export class Employee {
	id: number;
	ssn: number;
	active: boolean;
	firstName: string;
	middleName: string;
	lastName: string;
	gender: 'M'|'F';
	dateEmployment: string;
	emailAddress: string;
	phoneNumber: string;
	jobTitle: string;
	dateEmploymentEnd: string;
	dateOfBirth: string;
	dateOfRetirement: string;
	//'Date of Birth': Date;
	payFrequency: 'M'|'W';
	type: string;
	regno: string;
}

export class User {
	id: number;
	username: string;
	name: string;
	status: 'A'|'D';
	type: 'E'|'A';
	emailAddress: string;
	groups: any;
}

export class Group {
	id: number;
	name: string;
	description: string;
	domain: string;
	permissions: number
	/*User_Management: boolean;
	Group_Management: boolean;
	Permission_Management: boolean;
	Employer_Management: boolean;
	C_Rate_Management: boolean;
	S_Rate_Management: boolean;
	Report_Permission: boolean;
	CRM_Management: boolean;
	Schedule_Management: boolean;*/
}

export class PensionPeriod {
	id: number;
	periodStart: string;
	periodEnd: string;
	age: number;
}

export class ContributionRate {
	id: number;
	periodStart: string;
	periodEnd: string;
	totalRate: number;
	regularEmployerRate: number;
	regularEmployeeRate: number;
	injuryEmployerRate: number;
	injuryEmployeeRate: number;
	governmentPensionableEmployerRate: number;
	governmentPensionableEmployeeRate: number;
}

export class SalaryRate {
	id: number;
	periodStart: string;
	periodEnd: string;
	ceilingMonthly: number;
	floorMonthly: number;
	ceilingWeekly: number;
	floorWeekly: number;
}

export class RegisterEmployer {
	id: number;
	name: string;
	userId: number;
	registrationNumber: number;
	emailAddress:string;
	status: string;
	contactPerson: string;
	contactNumber: string;
}

export class RegisterInsured {
	id: number;
	name: string;
	userId: number;
	ssn: number;
	emailAddress:string;
	status: string;
	mobileNumber: string;
	contactNumber: string;
	addressOne: string;
	addressTwo: string;
	addressThree: string;
	insuredRegisteredQueries: RegisterInsuredQuery[];
}

export class RegisterInsuredQuery {
	id: number;
	question: string;
	answer: string;
	valid: boolean;
	userStatus: string;
}

export class ScheduleMonthly {
	id: number;
	//scheduleId: number;
	ssn: number;
	firstName: string;
	lastName: string;
	actualEarnings: number;
	weeksWorked: number;
	insurableEarnings: number;
	employerContribution: number;
	employeeContribution: number;
	//used for calculating contibution rate for employee and employer
	type:string;
}

export class ScheduleWeekly {
	id: number;
	//scheduleId: number;
	ssn: number;
	firstName: string;
	lastName: string;
	weekOne: number;
	weekTwo: number;
	weekThree: number;
	weekFour: number;
	weekFive: number;
	insurableEarnings: number;
	employerContribution: number;
	employeeContribution: number;
	//used for calculating contibution rate for employee and employer
	type:string;
}

export class Schedule {
	id: number;
	void: string;
	voidReason: string;
	year: number;
	month: number;
	submissionDate: string;
	registrationNumber: number;
	transactionId: string;
	scheduleNumber: number;
	schedulesMonthly: ScheduleMonthly[];
	schedulesWeekly: ScheduleWeekly[];
	surcharge: number;
	interest: number;
	uploadMethod: string;
	downloaded: number;
}

export class NonSubmission {
	id: number;
	registrationNumber: number;
	year: number;
	month: number;
	reason: string;
}

export class Message {
	id: number;
    userId: number;
    groupId: null;
    subject: string;
    message: string;
    status: number;
    dateModified: string;
    userModified: number;
}

export class Event {
	id: number;
	title: string;
	details: string;
	duration: number;
	minute: string;
	hour: string;
	dayWeek: string;
	dayMonth: string;
	weekMonth: string;
	weekYear: string;
	month: string;
	year: string;
}

export class Reconciliation {
	id: number;
    registrationNumber: number;
    userId: number;
    scheduleNumber: number;
    year: number;
    month: number;
    weeks: number;
    amount: number;
    reason: string;
    approval: boolean;
}

export class ReconciliationQuery {
	id: number;
    userId: number;
    employer: string;
    year: number;
    from: number;
    to: number;
    amount: number;
}
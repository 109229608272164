import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
    selector: 'schedule-list',
    templateUrl: './schedule-list.component.html'
})
export class ScheduleListComponent extends BaseComponent {
    @Input() scheduleIndex: any;
    //@Input() scheduleID: string;
    //@Output() scheduleIDChange = new EventEmitter();

    @Output() scheduleChange: EventEmitter<any> = new EventEmitter();

    increment: number;
    stack: number;
    page: number;
    listItemSelected: string;
    listPeriodSelected: string;
    listYearSelected: string;

    constructor() {
        super();
    	this.increment = 5;
    	this.page = 0;
        this.stack = 0;
    }

    ngOnInit(): void {
        if (this.arrayFromObject(this.scheduleIndex.years, 'year').length != 0) {
            setTimeout(() =>this.setScheduleID(
                this.arrayFromObject(this.arrayFromObject(this.arrayFromObject(this.scheduleIndex.years, 'year')[0].periods, 'month','desc')[0].schedules, 'number', 'asc')[0].id,
                this.arrayFromObject(this.arrayFromObject(this.scheduleIndex.years, 'year')[0].periods, 'month','desc')[0].month,
                this.arrayFromObject(this.scheduleIndex.years, 'year')[0].year,
                (this.arrayFromObject(this.arrayFromObject(this.arrayFromObject(this.scheduleIndex.years, 'year')[0].periods, 'month','desc')[0].schedules, 'number', 'asc')[0].type == 'nonSubmission')
                ), 0);
        }
    }

    setScheduleID(id:string, period:string = "", year:string = "", nonSubmission:boolean = false) {
        //this.scheduleID = id;
        //this.scheduleIDChange.emit(this.scheduleID);
        this.listItemSelected = id;
        (nonSubmission?this.listItemSelected += 'non': null);
        this.listPeriodSelected = period+''+year;
        this.listYearSelected = year;
        this.scheduleChange.emit({'id':id, 'nonSubmission':nonSubmission});
    }

    pagerNext():void  {
    	if (this.page < this.getLastPage()) {
    		this.page += 1;
    	}
    }

    pagerPrevious():void  {
    	if (this.page > 0) {
    		this.page -= 1;
    	}
    }

    pagerFirst():void  {
    	this.page = 0;
    }

    pagerLast():void {
    	this.page = this.getLastPage();
    }

    getLastPage():number {
    	return Math.floor(this.arrayFromObject(this.scheduleIndex.years).length / this.increment);
    }

    addStack():void {
        if (this.stack < this.getLastPage()) {
            this.stack += 1;
        }
    }

    arrayFromObject(obj:any, val:string = '', direction:string = 'desc'):any[] {
        //var returnArray = Object.values(obj);
        var returnArray = Object.keys(obj).map(function(itm) { return obj[itm]; });
        if (direction == 'desc') {
            returnArray.sort( function(prop1, prop2) {
                if ( prop1[val] > prop2[val] ){
                    return -1;
                }else if( prop1[val] < prop2[val] ){
                    return 1;
                }else{
                    return 0;  
                }
            });
        } else {
            returnArray.sort( function(prop1, prop2) {
                if ( prop1[val] < prop2[val] ){
                    return -1;
                }else if( prop1[val] > prop2[val] ){
                    return 1;
                }else{
                    return 0;  
                }
            });
        }
        
        return returnArray;
    }
}
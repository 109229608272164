import { Component, OnInit, ViewChild } from '@angular/core';

import { BaseEntryComponent } from '../base.entry.component';
import { ScheduleViewComponent } from '../components/schedule-view.component';

import { HTTPService } from '../services/http.service';
import { Schedule } from '../services/elements';

@Component({
    selector: 'admin-void-submission',
    templateUrl: './admin-void-submission.component.html',
})
export class AdminVoidSubmissionComponent extends BaseEntryComponent implements OnInit {
    @ViewChild('scheduleView') scheduleView: ScheduleViewComponent;
    order:string = 'ascending';
    selected:string = null;
    returnFile: any;
    schedules:any;
    selectSchedule:any;
    private employerSchedules: Schedule[];



    constructor(private _httpService: HTTPService ) {
        super();
    }

    ngOnInit(): void {
        var obj2 = {
                "search": [
                    {
                        "column":"void",
                        "operator":"=",
                        "value":"1"
                    },
                    {
                        "append":"and",
                        "column":"submissionDate",
                        "operator":"!=",
                        "value":"NULL"
                    },
                    {
                        "append":"and",
                        "column":"registrationNumber",
                        "operator":"!=",
                        "value":'621234'
                    },
                    {
                        "append":"and",
                        "column":"registrationNumber",
                        "operator":"!=",
                        "value":'621235'
                    }
                ]
            }
        this._httpService.genericPromise('schedule/basic/retrieve/any', obj2)
        .then(
                (data) => {this.returnData = data ; this.onVoidSubmissionResponce();}
            );
    }

    onVoidSubmissionResponce() {
        if (this.returnData.message == "retrieval successful") {
            this.employerSchedules = this.returnData.data;
            this.setComponentList(this.returnData.data);
        } else {

        }
        this.scheduleView.loaded = true;
    }


    focusFunction(row:any):void {
        if(row != undefined) {
            this.rowSelected = row['id'];
            this.schedules = row;
        }
        var obj4 = {
                "search": [
                    {
                        "column":"id",
                        "operator":"=",
                        "value":row['id']
                    }
                ]
            }
            this._httpService.retrievePromise('schedule', obj4)
            .then(
                    (data) => {this.returnData = data ; this.onScheduledetailLoad();}
                );
    }

    onScheduledetailLoad() {
        if (this.returnData.message == "retrieval successful") {
            this.selectSchedule = this.returnData.data[0];
            this.scheduleView.setSummary(this.selectSchedule);
        } else {
        }
    }

    searchEntry(ev:Event):void {
        //console.log((<HTMLTextAreaElement>ev.target).value);
        this.searchValue = (<HTMLTextAreaElement>ev.target).value.toLowerCase();
        this.componentList = this.employerSchedules.filter(function( obj ) {
            return (obj['id'].toString().toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1);
        });
    }

}
import { Component, OnInit } from '@angular/core';
import { ScheduleService } from '../services/elements.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html'
})
export class PaymentComponent implements OnInit {

  constructor(public _ScheduleService:ScheduleService) { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HTTPService } from './services/http.service';
import { BaseComponent } from './base.component';

import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'forgotpassword-reset',
  templateUrl: './forgotpassword-reset.component.html',
})
export class ForgotpasswordResetComponent extends BaseComponent implements OnInit {
	jwtHelper: JwtHelperService = new JwtHelperService();
	key: string;

	password: string;
	confirmation: string;

	constructor (
		private router: Router,
		private route: ActivatedRoute,
		private _httpService: HTTPService,
		) {
		super();
	}

	ngOnInit() {
		this.key = this.route.snapshot.paramMap.get('key');
 	}

 	onSubmit() { 
		this.disabled = true;
		this.message = "submitting.........";
		var obj = { 
			"key":this.key, 
			"password":this.password, 
		};
		this._httpService.authPromise('user/finalise', obj)
		.then(
				(data) => {this.returnData = data ; this.onResponce();}
			);
	}

	onResponce() {
		if (this.returnData.message == "reforge successful") {
			this.active = false;
		} else {
			this.message = "Invalid Request.";
			this.disabled = false;
			this.resetForm();
		}
	}
}

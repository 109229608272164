import { Component, OnInit, ApplicationRef } from '@angular/core';

import { BaseEntryComponent } from '../base.entry.component';

import { HTTPService } from '../services/http.service';
import { GroupsService } from '../services/elements.service';
import { Group } from '../services/elements';

@Component({
    selector: 'admin-group',
    templateUrl: './admin-group.component.html',
})
export class AdminGroupComponent extends BaseEntryComponent implements OnInit {
    //values in form
    grpName: string;
    grpDescription: string;
    User_Management: boolean;
    Group_Management: boolean;
    Pension_Management: boolean;
    Employer_Management: boolean;
    C_Rate_Management: boolean;
    S_Rate_Management: boolean;
    Report_Permission: boolean;
    CRM_Management: boolean;
    Schedule_Management: boolean;
    Schedule_View:boolean;
    Message_Management:boolean;
    Query_Management:boolean;

    editObj:any;
    loadedGroups:boolean = false;


    constructor(private _httpService: HTTPService, private applicationRef: ApplicationRef, private groups: GroupsService) {
        super();
    }

    ngOnInit(): void {
        this.loadedGroups = false;
        this._httpService.retrievePromise('group')
        .then(
                (data) => {this.returnData = data ; this.onGroupResponce();}
            )
        .catch(
                (error) => this.handleError(error)
            );
    }

    focusFunction(row:any):void {
        if(row != undefined) {
            this.rowSelected = row['id'];
            this.grpName = row['name'];
            this.grpDescription = row['description'];
            this.Employer_Management = Boolean(row['permissions'] & 1);
            this.C_Rate_Management = Boolean(row['permissions'] & 2);
            this.S_Rate_Management = Boolean(row['permissions'] & 4);
            this.Report_Permission = Boolean(row['permissions'] & 8);
            this.CRM_Management = Boolean(row['permissions'] & 16);
            this.Schedule_View = Boolean(row['permissions'] & 32);
            this.User_Management = Boolean(row['permissions'] & 64);
            this.Group_Management = Boolean(row['permissions'] & 128);
            this.Pension_Management = Boolean(row['permissions'] & 256);
            this.Schedule_Management = Boolean(row['permissions'] & 512);
            this.Message_Management = Boolean(row['permissions'] & 1024);
            this.Query_Management = Boolean(row['permissions'] & 2048);
            this.resetForm();
        }
    }

    onSubmit() {
        this.editObj =
        {
            'name': this.grpName,
            'description': this.grpDescription,
            'domain': 'Admin',
            'permissions': (this.Employer_Management? 1 : 0) | (this.C_Rate_Management? 2 : 0) | (this.S_Rate_Management? 4 : 0) | 
                           (this.Report_Permission? 8 : 0) | (this.CRM_Management? 16 : 0) | (this.Schedule_View? 32 : 0) | 
                           (this.User_Management? 64 : 0) | (this.Group_Management? 128 : 0) | (this.Pension_Management? 256 : 0) | 
                           (this.Schedule_Management? 512 : 0) | (this.Message_Management? 1024 : 0) | (this.Query_Management? 2048 : 0)
        };
        if (this.rowSelected) {
            this.editObj.id = this.rowSelected;
        }
        this.disabled = true;
        this.message = "Saving.........";
        this._httpService.reforgePromise('group', this.editObj)
        .then(
                (data) => {this.returnData = data ; this.onResponceSave();}
            );
    }

    onResponceSave() {
        this.disabled = false;
        this.newEntry = false;
        if (this.returnData.message == "reforge successful") {
            //this.getEmployees();
            if (this.editObj.id) {
                for (var i=0; i < this.groups.groups.length; i++) {
                    if (this.groups.groups[i]['id'] == this.editObj.id) {
                        this.groups.groups[i].name = this.editObj.name;
                        this.groups.groups[i].description = this.editObj.description;
                        this.groups.groups[i].permissions = this.editObj.permissions;
                    }
                }
            } else {
                this.editObj.id = this.returnData.id;
                this.groups.groups.push(this.editObj);
            }
            this.componentList = this.groups.groups;
            document.getElementById("message").classList.remove('text-danger');
            document.getElementById("message").classList.add('text-success');
            this.message = "Save successful";
            setTimeout(() => {
                document.getElementById("message").classList.remove('text-success');
                document.getElementById("message").classList.add('text-danger');
                this.message = null
            }, 3000);
        } else {
            this.message = this.returnData.message;
        }
    }

    onCancel() {
        this.newEntry = false;
        this.resetForm();
        if (this.rowSelected) {
            for (var i=0; i < this.groups.groups.length; i++) {
                if (this.groups.groups[i]['id'].toString() == this.rowSelected) {
                    this.focusFunction(this.groups.groups[i]);
                }
            }
        } else {
            this.focusFunction(this.componentList[0]);
        }
    }

    newGroup() {
        this.newEntry = true;
        this.rowSelected = null;
        this.grpName = null;
        this.grpDescription = null;
        this.User_Management = false;
        this.Group_Management = false;
        this.Pension_Management = false;
        this.Employer_Management = false;
        this.C_Rate_Management = false;
        this.S_Rate_Management = false;
        this.Report_Permission = false;
        this.CRM_Management = false;
        this.Schedule_View = false;
        this.Schedule_Management = false;
        this.Message_Management = false;
        this.Query_Management = false;
        this.resetForm();
        setTimeout(() => document.getElementById('grpName').focus(), 0.1);
    }

     onGroupResponce() {
        this.loadedGroups = false;
        if (this.returnData.message == "retrieval successful") {
            this.groups.groups = this.returnData.data;
            this.focusFunction(this.groups.groups[0]);
            this.setComponentList(this.groups.groups);
            this.loadedGroups = true;
        } else {
        }
    }

    searchEntry(ev:Event):void {
        //console.log((<HTMLTextAreaElement>ev.target).value);
        this.searchValue = (<HTMLTextAreaElement>ev.target).value.toLowerCase();
        this.componentList = this.groups.groups.filter(function( obj ) {
            return (obj['name'].toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1);
        });
    }

}
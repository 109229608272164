import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'my-dashboard',
  templateUrl: './dashboard.component.html',})
export class DashboardComponent implements OnInit {
  constructor(
  private router: Router) {
  }

  ngOnInit() {
    
  }


}

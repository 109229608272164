import { Component, Input, ViewChild, ChangeDetectorRef, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseSelectComponent } from '../base.select.component';

import { Schedule } from '../services/elements';
import { ContributionRate } from '../services/elements';
import { SalaryRate } from '../services/elements';
import { PensionPeriod } from '../services/elements';
import { HTTPService } from '../services/http.service';
import { ScheduleService, ScheduleListService, UploadService } from '../services/elements.service';
import { MessagingService } from '../services/messaging.service';


import { UserService } from '../services/user.service';

declare var jQuery:any;

@Component({
    selector: 'schedule-upload',
    templateUrl: './schedule-upload.component.html'
})
export class ScheduleUploadComponent extends BaseSelectComponent implements OnInit {
    @Input() draggableSchedules: boolean;
    //allows for the binding of a Ngform validation to a class attribute
    order:string = 'ascending';
    selected:string = null;
    rowSelected:string;

    paymentSurcharge:number = 0;
    paymentInterest:number = 0;

    timer:any;

    schedulesToRemove:number;
    schedulesRemoved:number;

    constructor(public cdRef:ChangeDetectorRef, 
        private router: Router, 
        public _httpService: HTTPService, 
        public _user:UserService, 
        private _UploadService:UploadService, 
        public _ScheduleService:ScheduleService, 
        private _ScheduleListService:ScheduleListService, 
        private _messagingService: MessagingService 
        ) 
    {
        super();
    }

    ngOnInit() {
        this._ScheduleService.setSummary();
        this.navToSummary();
     }

    navToSummary() {
        //navigate to the summary tab crude
        if (document.getElementById("component")){
            if (document.getElementById("monthlyTab")) {
                document.getElementById("monthlyTab").classList.remove('active');
            }
            if (document.getElementById("weeklyTab")) {
                document.getElementById("weeklyTab").classList.remove('active');
            }
            document.getElementById("reportTab").classList.add('active');
            document.getElementById("monthly").classList.remove('active');
            document.getElementById("weekly").classList.remove('active');
            document.getElementById("report").classList.add('active')
            document.getElementById("report").classList.remove('out');;
            document.getElementById("report").classList.add('in');
        }
    }

    //prevents ExpressionChangedAfterItHasBeenCheckedError
    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    autoSave(ev:Event) {
        this._ScheduleService.setSummary();        
        clearTimeout(this.timer); //reset timer each time a change is made
        var that = this;
        this.timer = setTimeout(function() {
            that._ScheduleService.schedule.registrationNumber = that._user.user.aud
            that._httpService.genericPromise('schedule/save/true', that._ScheduleService.schedule)
            .then(
                    (data) => {that.returnData = data ; that.onResponceSave();}
                );
        }, 3000);
    }

    sortMonthly( select:string ): void {
        if (select != this.selected) {
            this.order = 'ascending';
            this.selected = select;
        } else if (this.order == 'ascending') {
            this.order = 'descending';
        } else {
            this.selected = null;
        }
        if (this.selected) {
            if (this.order == 'ascending') {
                this._ScheduleService.schedule.schedulesMonthly.sort( function(name1, name2) {
                    if ( name1[select] < name2[select] ){
                        return -1;
                    }else if( name1[select] > name2[select] ){
                        return 1;
                    }else{
                        return 0;  
                    }
                });
            } else {
                this._ScheduleService.schedule.schedulesMonthly.sort( function(name1, name2) {
                    if ( name1[select] > name2[select] ){
                        return -1;
                    }else if( name1[select] < name2[select] ){
                        return 1;
                    }else{
                        return 0;  
                    }
                });
            }
        }
    }

    sortWeekly( select:string ): void {
        if (select != this.selected) {
            this.order = 'ascending';
            this.selected = select;
        } else if (this.order == 'ascending') {
            this.order = 'descending';
        } else {
            this.selected = null;
        }
        if (this.selected) {
            if (this.order == 'ascending') {
                this._ScheduleService.schedule.schedulesWeekly.sort( function(name1, name2) {
                    if ( name1[select] < name2[select] ){
                        return -1;
                    }else if( name1[select] > name2[select] ){
                        return 1;
                    }else{
                        return 0;  
                    }
                });
            } else {
                this._ScheduleService.schedule.schedulesWeekly.sort( function(name1, name2) {
                    if ( name1[select] > name2[select] ){
                        return -1;
                    }else if( name1[select] < name2[select] ){
                        return 1;
                    }else{
                        return 0;  
                    }
                });
            }
        }
    }

    save() {
        this._ScheduleService.schedule.registrationNumber = this._user.user.aud
        this._httpService.reforgePromise('schedule', this._ScheduleService.schedule)
        .then(
                (data) => {this.returnData = data ; this.onResponceSave();}
            );
    }

    submit() {
        clearTimeout(this.timer);
        this._messagingService.createMessageAlert("Submitting Schedule....", false);
        for (var i=0; i < this._UploadService.schedules.length; i++) {
            this._UploadService.schedules[i].registrationNumber = this._user.user.aud;
        }
        //this._ScheduleService.schedule.registrationNumber = this._user.user.aud
        this._httpService.genericPromise('schedule/submit/many/true', this._UploadService.schedules)
        .then(
                (data) => {this.returnData = data ; this.onResponceSubmit();}
            );
    }

    onResponceSave() {
        if (this.returnData.message == "reforge successful") {
        } else {
            //this.message = "An error occurred.";
        }
    }

    onResponceSubmit() {
        //console.log(this.returnData);
        if (this.returnData.message == "reforge successful") {
            // this._ScheduleService.schedule = this.returnData.record;
            // this._ScheduleService.schedule.month = Number(this._ScheduleService.schedule.month);
            // this._ScheduleService.schedule.year = Number(this._ScheduleService.schedule.year);
            // this._ScheduleListService.schedule.push(this._ScheduleService.schedule);
            // for (var i=0; i < this._UploadService.schedules.length; i++) {
            //     this._UploadService.schedules[i].submissionDate = (new Date()).toString();
            //     this._UploadService.schedules[i].month = Number(this._UploadService.schedules[i].month);
            //     this._UploadService.schedules[i].year = Number(this._UploadService.schedules[i].year);
            //     this._ScheduleListService.schedule.push(this._UploadService.schedules[i]);
            //     console.log(this._UploadService.schedules[i]);
            // }
            this._UploadService.schedules = this.returnData.records;
            this._ScheduleService.schedule.submissionDate = (new Date()).toString();
            for (var i=0; i < this._UploadService.schedules.length; i++) {
                this._ScheduleListService.schedule.push(Object.assign({}, this._UploadService.schedules[i]));
            }
            this._messagingService.hideMessageAlert();
            jQuery(document.getElementById('successModal')).modal('show');
        } else {
            this._messagingService.createMessageAlert("Error in Schedule", true);
        }
    }

    afterSubmission() {
        jQuery(document.getElementById('successModal')).modal('hide');
        this.router.navigate(['/management/schedules']);
    }

    cancelSchedule() {
        this._messagingService.createMessageAlert("Cancelling Schedule...", true);
        this.schedulesToRemove = this._UploadService.schedules.length;
        this.schedulesRemoved = 0;
        for (var i=0; i < this._UploadService.schedules.length; i++) {
            var obj = {
                "id": this._UploadService.schedules[i].id,
                "void": '1'
            }
            this._httpService.genericPromise('schedule/void', obj)
            .then(
                    (data) => {this.returnData = data ; this.onResponceCancel();}
                );
        }
    }

    onResponceCancel() {
        if (this.returnData.message == "reforge successful") {
            this.schedulesRemoved++;
            if (this.schedulesRemoved >= this.schedulesToRemove) {
                this._messagingService.hideMessageAlert();
                jQuery(document.getElementById('cancelAfterModal')).modal('show');
            }
        } else {
            this._messagingService.createMessageAlert("Error Removing Schedule", true);
        }
    }

    continueAfterCancel() {
        this.router.navigate(['/management/schedules']);
        this._ScheduleService.schedule = new Schedule();
        this._UploadService.schedules = [];
    }

    keytab(event:KeyboardEvent, type:string){
        var element;
        try {
            switch (type) {
                case "actual":
                     element = event.srcElement.parentElement.parentElement.nextSibling.nextSibling.childNodes[1];
                     break;
                case "weeks":
                     element = event.srcElement.parentElement.parentElement.nextSibling.childNodes[7].childNodes[1].childNodes[3];
                     break;
                case "actualWeeks":
                     element = event.srcElement.parentElement.parentElement.nextSibling.nextSibling.childNodes[1].childNodes[3];
                     break;
                case "actualWeeksLast":
                     element = event.srcElement.parentElement.parentElement.parentElement.nextSibling.childNodes[7].childNodes[1].childNodes[3];
                     break;
                default:
                     // code...
                     break;
            }
        }
        catch(err) {
            //console.log(err.message);
        }
        if(element != null) {
            (<HTMLTextAreaElement>element).focus();
            (<HTMLTextAreaElement>element).select();
        }
    }

    internalNumber(number:string) {
        return parseFloat(number);
    }

    validateSchedule():boolean {
        var countInvalid = 0;
        for (var i=0; i < this._ScheduleService.schedule.schedulesWeekly.length; i++) {
            if (Number(this._ScheduleService.schedule.schedulesWeekly[i]['weekOne']) + Number(this._ScheduleService.schedule.schedulesWeekly[i]['weekTwo']) + Number(this._ScheduleService.schedule.schedulesWeekly[i]['weekThree']) + Number(this._ScheduleService.schedule.schedulesWeekly[i]['weekFour']) + Number(this._ScheduleService.schedule.schedulesWeekly[i]['weekFive']) <= 0) {
                countInvalid +=1;
            }
        }
        if (countInvalid > 0) {
            return false
        } else {
            return true
        }
    }

    payment() {

    }

}
import { Component, OnInit, ApplicationRef } from '@angular/core';

import { BaseEntryComponent } from '../base.entry.component';

import { ReturnData } from '../services/return.data';
import { EmployeesService } from '../services/elements.service';
import { ScheduleService } from '../services/elements.service';
import { UserService } from '../services/user.service';
import { Employee } from '../services/elements';
import { HTTPService } from '../services/http.service';

declare var jQuery:any;

@Component({
    selector: 'user-employee',
    templateUrl: './user-employee.component.html',
})
export class UserEmployeeComponent extends BaseEntryComponent implements OnInit {
    //values in form
    ssn: string;
    empFirstName: string;
    empMiddleName: string;
    empLastName: string;
    empGender: string = '';
    empDateOfBirth: string;
    empDateOfEmployment: string;
    empDateOfDischarge: string;
    empFrequency: string = '';
    empJobTitle: string;
    empEmail: string;
    empPhone: string;
    empStatus: boolean;

    employeeObj:any;


    constructor(private _httpService: HTTPService, 
        private applicationRef: ApplicationRef, 
        private _employees: EmployeesService, 
        private _ScheduleService:ScheduleService, 
        private user:UserService
        ) {
        super();
    }

    ngOnInit(): void {
        this.focusFunction(this._employees.employees[0]);
        this.setComponentList(this._employees.employees);
    }

    focusFunction(row:any):void {
        if(row != undefined) {
            this.message = null;
            this.rowSelected = row['id'];
            this.ssn = row['ssn'];
            this.empFirstName = row['firstName'];
            this.empMiddleName = row['middleName'];
            this.empLastName = row['lastName'];
            this.empGender = row['gender'];
            this.empDateOfBirth = row['dateOfBirth'] ?  new Date((row['dateOfBirth']+' 00:00').replace(/-/g, "/")).toISOString().substring(0, 10) : null;
            this.empDateOfEmployment = row['dateEmployment'] ?  new Date((row['dateEmployment']+' 00:00').replace(/-/g, "/")).toISOString().substring(0, 10) : null;
            this.empDateOfDischarge = row['dateEmploymentEnd'] ? new Date((row['dateEmploymentEnd']+' 00:00').replace(/-/g, "/")).toISOString().substring(0, 10) : null;
            this.empFrequency = row['payFrequency'];
            this.empJobTitle = row['jobTitle'];
            this.empEmail = row['emailAddress'];
            this.empPhone = row['phoneNumber'];
            this.empStatus = row['active'];
            this.active = false;
            this.resetForm();
        }
    }

    onSubmit() {
        //set the selected after saving a new employee
        if (this.empDateOfDischarge != null) {
            this.dischargeDisclaimer();
        } else {
            this.commitSubmit();
        }
    }

    onResponceSave() {
        this.disabled = false;
        if (this.returnData.message == "reforge successful") {
            //this.getEmployees();
            this.componentList = this._employees.employees;
            document.getElementById("message").classList.remove('text-danger');
            document.getElementById("message").classList.add('text-success');
            this.message = "Save successful";
            setTimeout(() => {
                this.message = null
            }, 3000);
            this.newEntry = false;
            if (this.employeeObj.id) {
                for (var i=0; i < this._employees.employees.length; i++) {
                    if (this._employees.employees[i]['id'] == this.employeeObj.id) {
                        if (this.employeeObj.dateEmploymentEnd) {
                            this._employees.employees.splice(i,1);
                            this._ScheduleService.removeEmployee(this._employees.employees[i].ssn);
                            this.focusFunction(this.componentList[0]);
                        } else {
                            var pre = this._employees.employees[i].active; //quick fix store temp
                            this._employees.employees[i].ssn = this.employeeObj.ssn;
                            this._employees.employees[i].active = this.employeeObj.active;
                            this._employees.employees[i].firstName = this.employeeObj.firstName;
                            this._employees.employees[i].middleName = this.employeeObj.middleName;
                            this._employees.employees[i].lastName = this.employeeObj.lastName;
                            this._employees.employees[i].gender = this.employeeObj.gender;
                            this._employees.employees[i].dateEmployment = this.employeeObj.dateEmployment;
                            this._employees.employees[i].emailAddress = this.employeeObj.emailAddress;
                            this._employees.employees[i].phoneNumber = this.employeeObj.phoneNumber;
                            this._employees.employees[i].jobTitle = this.employeeObj.jobTitle;
                            this._employees.employees[i].payFrequency = this.employeeObj.payFrequency;
                            if(this._employees.employees[i].active && !pre) {
                                this._ScheduleService.addEmployee(this._employees.employees[i]);
                            } else if (!this._employees.employees[i].active) {
                                //remove from schedule
                                this._ScheduleService.removeEmployee(this._employees.employees[i].ssn);
                            }
                        }
                    }
                }
            } else {
                this.employeeObj.id = this.returnData.id;
                this._employees.employees.push(this.employeeObj);
                //this._ScheduleService.addEmployee(this.employeeObj);
                this.focusFunction(this.componentList[this.componentList.length-1]);
                //pull missing employee information
                var obj = {
                        "search": [
                            {
                                "column":"id",
                                "operator":"=",
                                "value":this.returnData.id
                            }
                        ]
                    }
                this._httpService.retrievePromise('employee', obj)
                .then(
                        (data) => {this.returnData = data ; this.onEmployeeResponce();}
                    );
            }
        } else if (this.returnData.message == 'Invalid employee.') {
            document.getElementById("message").classList.remove('text-success');
            document.getElementById("message").classList.add('text-danger');
            this.message = "Invalid employee information";
        } else {
            document.getElementById("message").classList.remove('text-success');
            document.getElementById("message").classList.add('text-danger');
            this.message = this.returnData.message;
        }
    }

    onEmployeeResponce() {
        if (this.returnData.message == "retrieval successful") {
            console.log (this.returnData);
            for (var i=0; i < this._employees.employees.length; i++) {
                if (this._employees.employees[i]['id'] == this.returnData.data[0].id) {
                    this._employees.employees[i] = this.returnData.data[0]
                    this._ScheduleService.addEmployee(this._employees.employees[i]);
                }
            }
        } else {

        }
    }

    commitSubmit() {
        var route;
        this.employeeObj =
        {
            ssn: this.ssn,
            'active': this.empStatus,
            'firstName': this.empFirstName,
            'middleName': this.empMiddleName,
            'lastName': this.empLastName,
            'gender': this.empGender,
            'dateEmployment': this.empDateOfEmployment,
            'emailAddress': this.empEmail,
            'phoneNumber': this.empPhone,
            'jobTitle': this.empJobTitle,
            //'Date of Birth': this.empDateOfBirth,
            'payFrequency': this.empFrequency,
        };
        if (this.newEntry) {
            this.employeeObj.dateOfBirth = this.empDateOfBirth
            route = 'employee/save';
        } else {
            route = 'employee/reforge/any';
        }
        if (this.rowSelected) {
            this.employeeObj.id = this.rowSelected;
        } else {
            this.employeeObj.type =  "regular";
            this.employeeObj.regno = this.user.user.aud;
        }
        if (this.empDateOfDischarge) {
            this.employeeObj.dateEmploymentEnd = this.empDateOfDischarge
        }
        document.getElementById("message").classList.remove('text-danger');
        document.getElementById("message").classList.add('text-success');
        this.message = "Saving.........";
        this.disabled = true;

        this._httpService.genericPromise(route, this.employeeObj)
        .then(
                (data) => {this.returnData = data ; this.onResponceSave();}
            );
    }

    onCancel() {
        this.newEntry = false;
        this.resetForm();
        if (this.rowSelected) {
            for (var i=0; i < this._employees.employees.length; i++) {
                if (this._employees.employees[i]['id'].toString() == this.rowSelected) {
                    this.focusFunction(this._employees.employees[i]);
                }
            }
        } else {
            this.focusFunction(this.componentList[0]);
        }
    }

    newEmployee() {
        this.newEntry = true;
        this.rowSelected = null;
        this.ssn = null;
        this.empFirstName = null;
        this.empMiddleName = null;
        this.empLastName = null;
        this.empGender = null;
        this.empDateOfBirth = null;
        this.empDateOfEmployment = null;
        this.empDateOfDischarge = null;
        this.empFrequency = null;
        this.empJobTitle = null;
        this.empEmail = null;
        this.empPhone = null;
        this.empStatus = true;
        this.resetForm();
        setTimeout(() => document.getElementById('Employee_SSN').focus(), 0.1);
    }

    searchEntry(ev:Event):void {
        //console.log((<HTMLTextAreaElement>ev.target).value);
        this.searchValue = (<HTMLTextAreaElement>ev.target).value.toLowerCase();
        this.componentList = this._employees.employees.filter(function( obj ) {
            return (obj['firstName'].toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1 || obj['lastName'].toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1);
        });
    }

    dischargeDisclaimer():void {
        jQuery(document.getElementById('dischargeDisclaimer')).modal('show');
    }

    checkEmployee():boolean {
        for (var i=0; i < this._employees.employees.length; i++) {
            if (this._employees.employees[i]['ssn']+'' == this.ssn && this.newEntry) {
                return true;
            }
        }

        return false;
    }

}
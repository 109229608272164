import { Injectable }     from '@angular/core';
import { CanActivate, CanDeactivate }    from '@angular/router';
import { Router } from '@angular/router';

import { UserService } from './user.service';
import { ScheduleService } from '../services/elements.service';

//Prevent Logged in users who are not Administrators from accessing Admin pages
@Injectable()
export class AuthGuardAdmin implements CanActivate {
	constructor (
	    private user:UserService,
	    private router: Router
	    ) {}

	canActivate() {
		if (this.user.user && this.user.user.typ == "A"){
			return true;
		} else if (this.user.user) {
			this.router.navigate(['/dashboard']);
			return false;
		} else {
			this.router.navigate(['/login']);
			return false;
		}
	}
}

//Prevent Logged in users who are not Employers from accessing Employer pages
@Injectable()
export class AuthGuardEmployer implements CanActivate {
  constructor (
	    private user:UserService,
	    private router: Router
	    ) {}

	canActivate() {
		if (this.user.user && this.user.user.typ == "E" ){
			return true;
		} else if (this.user.user) {
			this.router.navigate(['/dashboard']);
			return false;
		} else {
			this.router.navigate(['/login']);
			return false;
		}
	}
}

//Prevent User who do not have an active schedule from accessing these pages
@Injectable()
export class AuthGuardSchedule implements CanActivate {
  constructor (
	    private user:UserService,
	    private router: Router,
	    public _ScheduleService:ScheduleService
	    ) {}

	canActivate() {
		if (this._ScheduleService.schedule.year ){
			return true;
		} else {
			this.router.navigate(['/management/schedules']);
			return false;
		}
	}
}

//Prevent users who are not logged in to access pages that require authorization
@Injectable()
export class AuthGuard implements CanActivate {
  constructor (
	    private user:UserService,
	    private router: Router
	    ) {}

	canActivate() {
		if (this.user.user){
			return true;
		} else {
			this.router.navigate(['/login']);
			return false;
		}
	}
}

//Prevent users who are logged in for accessing pages that require users to log out
@Injectable()
export class AuthGuardLoggedOut implements CanActivate {
    constructor (
    private user:UserService,
    private router: Router
    ) {}

	canActivate() {
		if (!this.user.user && !localStorage.getItem("id_token")){
			return true;
		} else {
			this.router.navigate(['/dashboard']);
			return false;
		}
	}
}

//Prevent users who are logged in for accessing pages that require users to log out
@Injectable()
export class AuthGuardForceLoggedOut implements CanActivate {
    constructor (
    private user:UserService,
    private router: Router
    ) {}

	canActivate() {
		if (!this.user.user && !localStorage.getItem("id_token")){
			localStorage.removeItem('id_token');
	    	sessionStorage.clear();
	    	this.user.user = null;
		}
		return true;
	}
}
import { Component, Input, ViewChild, ChangeDetectorRef, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseSelectComponent } from '../base.select.component';

import { Schedule } from '../services/elements';
import { ContributionRate } from '../services/elements';
import { SalaryRate } from '../services/elements';
import { PensionPeriod } from '../services/elements';
import { HTTPService } from '../services/http.service';
import { ReturnData } from '../services/return.data';
import { ScheduleService, ScheduleListService } from '../services/elements.service';
import { MessagingService } from '../services/messaging.service';


import { UserService } from '../services/user.service';

declare var jQuery:any;

@Component({
    selector: 'schedule-entry',
    templateUrl: './schedule-entry.component.html'
})
export class ScheduleEntryComponent extends BaseSelectComponent implements OnInit {
    @Input() draggableSchedules: boolean;
    //allows for the binding of a Ngform validation to a class attribute
    @ViewChild('monthlyForm') formMonthly:NgForm;
    @ViewChild('weeklyForm') formWeekly:NgForm;
    order:string = 'ascending';
    selected:string = null;
    rowSelected:string;
    saveStatus:string;

    paymentSurcharge:number = 0;
    paymentInterest:number = 0;

    timer:any;

    constructor(public cdRef:ChangeDetectorRef, 
        private router: Router, 
        public _httpService: HTTPService, 
        public _user:UserService, 
        public _ScheduleService:ScheduleService, 
        private _ScheduleListService:ScheduleListService,
        private _messagingService: MessagingService 
        ) {
        super();
    }

    ngOnInit() {
        this.saveStatus = ' (Saved)';
        this._ScheduleService.setSummary();
        this.navToSummary();
     }

    navToSummary() {
        //navigate to the summary tab crude
        if (document.getElementById("component")){
            if (document.getElementById("monthlyTab")) {
                document.getElementById("monthlyTab").classList.remove('active');
            }
            if (document.getElementById("weeklyTab")) {
                document.getElementById("weeklyTab").classList.remove('active');
            }
            document.getElementById("reportTab").classList.add('active');
            document.getElementById("monthly").classList.remove('active');
            document.getElementById("weekly").classList.remove('active');
            document.getElementById("report").classList.add('active')
            document.getElementById("report").classList.remove('out');;
            document.getElementById("report").classList.add('in');
        }
    }

    //prevents ExpressionChangedAfterItHasBeenCheckedError
    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    autoSave(ev:Event = null) {
        this.saveStatus = ' (Saving...)';
        this._ScheduleService.setSummary();        
        clearTimeout(this.timer); //reset timer each time a change is made
        var that = this;
        this.timer = setTimeout(function() {
            that._ScheduleService.schedule.registrationNumber = that._user.user.aud
            that._httpService.genericPromise('schedule/save/true', that._ScheduleService.schedule)
            .then(
                    (data) => {that.returnData = data ; that.onResponceSave();}
                );
        }, 3000);
    }

    sortMonthly( select:string ): void {
        if (select != this.selected) {
            this.order = 'ascending';
            this.selected = select;
        } else if (this.order == 'ascending') {
            this.order = 'descending';
        } else {
            this.selected = null;
        }
        if (this.selected) {
            if (this.order == 'ascending') {
                this._ScheduleService.schedule.schedulesMonthly.sort( function(name1, name2) {
                    if ( name1[select] < name2[select] ){
                        return -1;
                    }else if( name1[select] > name2[select] ){
                        return 1;
                    }else{
                        return 0;  
                    }
                });
            } else {
                this._ScheduleService.schedule.schedulesMonthly.sort( function(name1, name2) {
                    if ( name1[select] > name2[select] ){
                        return -1;
                    }else if( name1[select] < name2[select] ){
                        return 1;
                    }else{
                        return 0;  
                    }
                });
            }
        }
    }

    sortWeekly( select:string ): void {
        if (select != this.selected) {
            this.order = 'ascending';
            this.selected = select;
        } else if (this.order == 'ascending') {
            this.order = 'descending';
        } else {
            this.selected = null;
        }
        if (this.selected) {
            if (this.order == 'ascending') {
                this._ScheduleService.schedule.schedulesWeekly.sort( function(name1, name2) {
                    if ( name1[select] < name2[select] ){
                        return -1;
                    }else if( name1[select] > name2[select] ){
                        return 1;
                    }else{
                        return 0;  
                    }
                });
            } else {
                this._ScheduleService.schedule.schedulesWeekly.sort( function(name1, name2) {
                    if ( name1[select] > name2[select] ){
                        return -1;
                    }else if( name1[select] < name2[select] ){
                        return 1;
                    }else{
                        return 0;  
                    }
                });
            }
        }
    }

    save() {
        this._ScheduleService.schedule.registrationNumber = this._user.user.aud
        this._httpService.reforgePromise('schedule', this._ScheduleService.schedule)
        .then(
                (data) => {this.returnData = data ; this.onResponceSave();}
            );
    }

    submit() {
        clearTimeout(this.timer);
        this._messagingService.createMessageAlert("Submitting Schedule....", false);
        this._ScheduleService.schedule.registrationNumber = this._user.user.aud
        this._httpService.genericPromise('schedule/submit/true', this._ScheduleService.schedule)
        .then(
                (data) => {this.returnData = data ; this.onResponceSubmit();}
            );
    }

    onResponceSave() {
        if (this.returnData.message == "reforge successful") {
            this.saveStatus = ' (Saved)'
        } else {
            //this.message = "An error occurred.";
        }
    }

    onResponceSubmit() {
        //console.log(this.returnData);
        if (this.returnData.message == "reforge successful") {
            if(!this.returnData.record.schedulesMonthly.isArray) {
                this.returnData.record.schedulesMonthly = this.arrayFromObject(this.returnData.record.schedulesMonthly);
                this.returnData.record.schedulesWeekly = this.arrayFromObject(this.returnData.record.schedulesWeekly);
            }
            this._ScheduleService.schedule = this.returnData.record;
            this._ScheduleService.schedule.month = Number(this._ScheduleService.schedule.month);
            this._ScheduleService.schedule.year = Number(this._ScheduleService.schedule.year);
            this._ScheduleListService.schedule.push(Object.assign({}, this._ScheduleService.schedule));
            this._messagingService.createMessageAlert("Schedule Submitted Successfully", true);
        } else {
            this._messagingService.createMessageAlert("Error in Schedule", true);
        }
    }

    cancelSchedule() {
        this._messagingService.createMessageAlert("Cancelling Schedule...", true);
        var obj = {
            "id": this._ScheduleService.schedule.id,
            "void": '1'
        }
        this._httpService.genericPromise('schedule/void', obj)
        .then(
                (data) => {this.returnData = data ; this.onResponceCancel();}
            );
    }

    onResponceCancel() {
        if (this.returnData.message == "reforge successful") {
            this._messagingService.hideMessageAlert();
            jQuery(document.getElementById('cancelModalAfter')).modal('show');
        } else {
            this._messagingService.createMessageAlert("Error Removing Schedule", true);
        }
    }


    continueAfterCancel() {
        this.router.navigate(['/management/schedules']);
        this._ScheduleService.schedule = new Schedule();
    }

    keytab(event:KeyboardEvent, type:string){
        var element;
        try {
            switch (type) {
                case "actual":
                     element = event.srcElement.parentElement.parentElement.nextSibling.childNodes[0];
                     break;
                case "weeks":
                     element = event.srcElement.parentElement.parentElement.nextSibling.childNodes[3].childNodes[0].childNodes[1];
                     break;
                case "actualWeeks":
                     element = event.srcElement.parentElement.parentElement.nextSibling.childNodes[0].childNodes[1];
                     break;
                case "actualWeeksLast":
                     element = event.srcElement.parentElement.parentElement.parentElement.nextSibling.childNodes[3].childNodes[0].childNodes[1];
                     break;
                default:
                     // code...
                     break;
            }
        }
        catch(err) {
            //console.log(err.message);
        }
        if(element != null) {
            (<HTMLTextAreaElement>element).focus();
            (<HTMLTextAreaElement>element).select();
        }
    }

    internalNumber(number:string) {
        return parseFloat(number);
    }

    validateSchedule():boolean {
        var countInvalid = 0;
        for (var i=0; i < this._ScheduleService.schedule.schedulesWeekly.length; i++) {
            if (Number(this._ScheduleService.schedule.schedulesWeekly[i]['weekOne']) + Number(this._ScheduleService.schedule.schedulesWeekly[i]['weekTwo']) + Number(this._ScheduleService.schedule.schedulesWeekly[i]['weekThree']) + Number(this._ScheduleService.schedule.schedulesWeekly[i]['weekFour']) + Number(this._ScheduleService.schedule.schedulesWeekly[i]['weekFive']) <= 0) {
                countInvalid +=1;
            }
        }
        if (countInvalid > 0) {
            return false
        } else {
            return true
        }
    }

    arrayFromObject(obj:any, val:string = '', direction:string = 'desc') {
        //var returnArray = Object.values(obj);
        var returnArray = Object.keys(obj).map(function(itm) { return obj[itm]; });
        if (direction == 'desc') {
            returnArray.sort( function(prop1, prop2) {
                if ( prop1[val] > prop2[val] ){
                    return -1;
                }else if( prop1[val] < prop2[val] ){
                    return 1;
                }else{
                    return 0;  
                }
            });
        } else {
            returnArray.sort( function(prop1, prop2) {
                if ( prop1[val] < prop2[val] ){
                    return -1;
                }else if( prop1[val] > prop2[val] ){
                    return 1;
                }else{
                    return 0;  
                }
            });
        }
        
        return returnArray;
    }

    payment() {

    }

}
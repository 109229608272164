import { ReturnData } from './services/return.data';

export abstract class BaseComponent {
    returnData: ReturnData;
    message: string;
    active = true;
    disabled = false;

    resetForm() {
        this.active = false;
        setTimeout(() => this.active = true, 0);
    }

    handleError(error: any) {
        this.message = "An error occured.";
        this.disabled = false;
        this.resetForm();
    }

    getMonth(month:string = ''):string {
        switch (month.toString()) {
            case '1':
                return 'January';
            case '2':
                return 'February';
            case '3':
                return 'March';
            case '4':
                return 'April';
            case '5':
                return 'May';
            case '6':
                return 'June';
            case '7':
                return 'July';
            case '8':
                return 'August';
            case '9':
                return 'September';
            case '10':
                return 'October';
            case '11':
                return 'November';
            case '12':
                return 'December';
            default:
                return 'error';
        }
    }
}
import { Injectable }    from '@angular/core';
import { Router } from '@angular/router';

import { EmployeesService } from './elements.service';
import { ScheduleListService } from './elements.service';
import { NonSubmissionListService } from './elements.service';
import { ContributionRateService } from './elements.service';
import { SalaryRateService } from './elements.service';
import { PensionPeriodService } from './elements.service';
import { UsersService, InsuredUsersService } from './elements.service';
import { GroupsService } from './elements.service';
import { RegisterEmployerService } from './elements.service';
import { BaseComponent } from '../base.component';
import { HTTPService } from './http.service';
import { ScheduleService, UploadService, ScheduleNotDownloadedService, MessageService, RegisterInsuredService, EventService } from './elements.service';

@Injectable()
export class UserService extends BaseComponent{
    public user:any;
    public dataLoaded:boolean = true;
    public redirectUrl:string;
    numberLoaded: number;
    fullyLoaded: number;

    constructor( 
        private router: Router,
    	private _EmployeesService:EmployeesService,
		private _ScheduleListService:ScheduleListService,
        private _NonSubmissionListService:NonSubmissionListService,
		private _httpService: HTTPService,
        private _ContributionRateService: ContributionRateService,
        private _SalaryRateService: SalaryRateService,
        private _PensionPeriodService: PensionPeriodService,
        private _UsersService: UsersService,
        private _InsuredUsersService: InsuredUsersService,
        private _GroupsService: GroupsService,
        private _RegisterEmployerService: RegisterEmployerService,
        private _RegisterInsuredService: RegisterInsuredService,
        private _ScheduleService:ScheduleService,
        private _UploadService:UploadService,
        private _ScheduleNotDownloadedService:ScheduleNotDownloadedService,
        private _MessageService:MessageService,
        private _EventService: EventService
		) {
        super();
    	this.user = null;
        this.redirectUrl = null;
    }

    canDownloadNIMS() {
        for (var i=0; i < this.user.prm.length; i++) {
            ////Schedule Download Permission value
            if (this.user.prm[i] == 'ROLE_Admin_512') {
                return true;
            }
        }
        return false;
    }

    loadData() {
    	this.dataLoaded = false;
    	if (this.user.typ == 'A') {
			this.loadAdministrator();
		} else {
			this.loadEmployer();
		}
    }

    loadAdministrator() {
        this.numberLoaded = 0;
        this.fullyLoaded = 6;
        this._httpService.retrievePromise('rate/contribution')
        .then(
                (data) => {this.returnData = data ; this.onContributionResponce();}
            )
        .catch(
                (error) => this.handleError(error)
            );
        this._httpService.retrievePromise('rate/salary')
        .then(
                (data) => {this.returnData = data ; this.onSalaryResponce();}
            )
        .catch(
                (error) => this.handleError(error)
            );
        this._httpService.retrievePromise('pensionperiod')
        .then(
                (data) => {this.returnData = data ; this.onPensionResponce();}
            )
        .catch(
                (error) => this.handleError(error)
            );
        this._httpService.retrievePromise('message')
        .then(
                (data) => {this.returnData = data ; this.onMessageResponce();}
            )
        .catch(
                (error) => this.handleError(error)
            );
        this._httpService.retrievePromise('event')
        .then(
                (data) => {this.returnData = data ; this.onEventResponce();}
            )
        .catch(
                (error) => this.handleError(error)
            );
        var objn = {
            "search": [
                {
                    "column":"downloaded",
                    "operator":"=",
                    "value": '0'
                },
                {
                    "append":"and",
                    "column":"void",
                    "operator":"!=",
                    "value":"1"
                },
                {
                    "append":"and",
                    "column":"submissionDate",
                    "operator":"!=",
                    "value":"NULL"
                }
            ]
        }
        this._httpService.retrievePromise('schedule', objn)
        .then(
                (data) => {this.returnData = data ; this.onScheduleNotDownloadedResponce();}
            )
        .catch(
                (error) => this.handleError(error)
            );
	}

    loadEmployer() {
        this.numberLoaded = 0;
        this.fullyLoaded = 6;
        var obj = {
                "search": [
                    {
                        "column":"regno",
                        "operator":"=",
                        "value":this.user.aud
                    },
                    {
                        "append":"and",
                        "column":"dateEmploymentEnd",
                        "operator":"=",
                        "value":"NULL"
                    },
                ]
            }
        this._httpService.retrievePromise('employee', obj)
        .then(
                (data) => {this.returnData = data ; this.onEmployeeResponce();}
            )
        .catch(
                (error) => this.handleError(error)
            );
        var obj2 = {
            "search": [
                {
                    "column":"registrationNumber",
                    "operator":"=",
                    "value":this.user.aud
                },
                {
                    "append":"and",
                    "column":"void",
                    "operator":"!=",
                    "value":"1"
                },
                {
                    "append":"and",
                    "column":"submissionDate",
                    "operator":"!=",
                    "value":"NULL"
                }
            ]
        }
        this._httpService.genericPromise('schedule/basic/retrieve/any', obj2)
        .then(
                (data) => {this.returnData = data ; this.onScheduleResponce();}
            )
        .catch(
                (error) => this.handleError(error)
            );
        var obj3 = {
            "search": [
                {
                    "column":"registrationNumber",
                    "operator":"=",
                    "value":this.user.aud
                }
            ]
        }
        this._httpService.retrievePromise('nonsubmission', obj3)
        .then(
                (data) => {this.returnData = data ; this.onNonSubmissionResponce();}
            )
        .catch(
                (error) => this.handleError(error)
            );
        this._httpService.retrievePromise('rate/contribution')
        .then(
                (data) => {this.returnData = data ; this.onContributionResponce();}
            )
        .catch(
                (error) => this.handleError(error)
            );
        this._httpService.retrievePromise('rate/salary')
        .then(
                (data) => {this.returnData = data ; this.onSalaryResponce();}
            )
        .catch(
                (error) => this.handleError(error)
            );
        this._httpService.retrievePromise('pensionperiod')
        .then(
                (data) => {this.returnData = data ; this.onPensionResponce();}
            )
        .catch(
                (error) => this.handleError(error)
            );
    }

	onEmployeeResponce() {
        if (this.returnData.message == "retrieval successful") {
            this._EmployeesService.employees = this.returnData.data;
        } else {

        }
        this.loadComplete();
    }

    onScheduleResponce() {
        if (this.returnData.message == "retrieval successful") {
            this._ScheduleListService.schedule = this.returnData.data;
        } else {

        }
        this.loadComplete();
    }

    onNonSubmissionResponce() {
        if (this.returnData.message == "retrieval successful") {
            this._NonSubmissionListService.schedule = this.returnData.data;
        } else {

        }
        this.loadComplete();
    }

    onContributionResponce() {
        if (this.returnData.message == "retrieval successful") {
            this._ContributionRateService.rates = this.returnData.data;
        } else {
        }
        this.loadComplete();
    }

    onSalaryResponce() {
        if (this.returnData.message == "retrieval successful") {
            this._SalaryRateService.rates = this.returnData.data;
        } else {
        }
        this.loadComplete();
    }

    onPensionResponce() {
        if (this.returnData.message == "retrieval successful") {
            this._PensionPeriodService.periods = this.returnData.data;
        } else {
        }
        this.loadComplete();
    }

    onMessageResponce() {
        if (this.returnData.message == "retrieval successful") {
            this._MessageService.messages = this.returnData.data;
        } else {
        }
        this.loadComplete();
    }

    onEventResponce() {
        if (this.returnData.message == "retrieval successful") {
            this._EventService.event = this.returnData.data;
        } else {
        }
        this.loadComplete();
    }

    onScheduleNotDownloadedResponce() {
        if (this.returnData.message == "retrieval successful") {
            this._ScheduleNotDownloadedService.schedule = this.returnData.data;
        } else {

        }
        this.loadComplete();
    }

    onActiveScheduleResponce() {
        if (this.returnData.message == "retrieval successful") {
            this._ScheduleService.schedule.id = null;
            this._UploadService.schedules = [];
            if (this.returnData.data[0]) {
                this._ScheduleService.generate(this.returnData.data[0].year, this.returnData.data[0].month, this.returnData.data[0]);
                this._ScheduleService.schedule.id = this.returnData.data[0].id;
            }
            if (this.returnData.data.length > 1 || (this.returnData.data[0] && this.returnData.data[0].uploadMethod == 'EW')) {
                this._UploadService.schedules = this.returnData.data;
            }
            this.dataLoaded = true;
        } else {
        }
    }

    loadComplete() {
        this.numberLoaded ++;
        if (this.numberLoaded >= this.fullyLoaded) {
            if (this.user.typ == 'A') {
                this.dataLoaded = true;
            } else {
                var obj4 = {
                    "search": [
                        {
                            "column":"registrationNumber",
                            "operator":"=",
                            "value":this.user.aud
                        },
                        {
                            "append":"and",
                            "column":"void",
                            "operator":"!=",
                            "value":"1"
                        },
                        {
                            "append":"and",
                            "column":"submissionDate",
                            "operator":"=",
                            "value":"NULL"
                        }
                    ]
                }
                this._httpService.retrievePromise('schedule', obj4)
                .then(
                        (data) => {this.returnData = data ; this.onActiveScheduleResponce();}
                    )
                .catch(
                        (error) => this.handleError(error)
                    );
            }
            
        }
    }


    handleError(error:Error) {
        this.dataLoaded = true;
        localStorage.removeItem('id_token');
        sessionStorage.clear();
        this.user.user = null;
        this.router.navigate(['/login']);
        alert('system error!');
    }

}
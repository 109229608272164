import { Component, OnInit, ApplicationRef } from '@angular/core';

import { BaseEntryComponent } from '../base.entry.component';

import { HTTPService } from '../services/http.service';
import { SalaryRateService } from '../services/elements.service';
import { SalaryRate } from '../services/elements';

@Component({
    selector: 'admin-salary',
    templateUrl: './admin-salary.component.html',
})
export class AdminSalaryComponent extends BaseEntryComponent implements OnInit {
    //values in form
    rateFrom: string;
    rateTo: string;
    rateMonthlyCeiling: number;
    rateMonthlyFloor: number;
    rateWeeklyCeiling: number;
    rateWeeklyFloor: number;

    editObj:any;

    constructor(private _httpService: HTTPService, private applicationRef: ApplicationRef, private rates: SalaryRateService ) {
        super();
    }

    ngOnInit(): void {
        this.focusFunction(this.rates.rates[0]);
        this.setComponentList(this.rates.rates);
    }

    focusFunction(row:any):void {
        if(row != undefined) {
            this.rowSelected = row['id'];
            this.rateFrom = new Date((row['periodStart']).replace(/-/g, "/")).toISOString().substring(0, 10);
            this.rateTo = new Date((row['periodEnd']).replace(/-/g, "/")).toISOString().substring(0, 10);
            this.rateMonthlyCeiling = row['ceilingMonthly'];
            this.rateMonthlyFloor = row['floorMonthly'];
            this.rateWeeklyCeiling = row['ceilingWeekly'];
            this.rateWeeklyFloor = row['floorWeekly'];
            this.resetForm();
        }
    }

    onSubmit() {
        this.editObj =
        {
            'periodStart': this.rateFrom,
            'periodEnd': this.rateTo,
            'ceilingMonthly': this.rateMonthlyCeiling,
            'floorMonthly': this.rateMonthlyFloor,
            'ceilingWeekly': this.rateWeeklyCeiling,
            'floorWeekly': this.rateWeeklyFloor,
        };
        if (this.rowSelected) {
            this.editObj.id = this.rowSelected;
        }
        this.disabled = true;
        this.message = "Saving.........";
        this._httpService.reforgePromise('rate/salary', this.editObj)
        .then(
                (data) => {this.returnData = data ; this.onResponceSave();}
            );
    }

    onResponceSave() {
        this.newEntry = false;
        this.disabled = false;
        if (this.returnData.message == "reforge successful") {
            //this.getEmployees();
            if (this.editObj.id) {
                for (var i=0; i < this.rates.rates.length; i++) {
                    if (this.rates.rates[i]['id'] == this.editObj.id) {
                        this.rates.rates[i].periodStart = this.editObj.periodStart;
                        this.rates.rates[i].periodEnd = this.editObj.periodEnd;
                        this.rates.rates[i].ceilingMonthly = this.editObj.ceilingMonthly;
                        this.rates.rates[i].floorMonthly = this.editObj.floorMonthly;
                        this.rates.rates[i].ceilingWeekly = this.editObj.ceilingWeekly;
                        this.rates.rates[i].floorWeekly = this.editObj.floorWeekly;
                    }
                }
            } else {
                this.editObj.id = this.returnData.id;
                this.rates.rates.push(this.editObj);
            }
            this.componentList = this.rates.rates;
            document.getElementById("message").classList.remove('text-danger');
            document.getElementById("message").classList.add('text-success');
            this.message = "Save successful";
            setTimeout(() => {
                document.getElementById("message").classList.remove('text-success');
                document.getElementById("message").classList.add('text-danger');
                this.message = null
            }, 3000);
        } else {
            this.message = this.returnData.message;
        }
    }

    onCancel() {
        this.newEntry = false;
        this.resetForm();
        if (this.rowSelected) {
            for (var i=0; i < this.rates.rates.length; i++) {
                if (this.rates.rates[i]['id'].toString() == this.rowSelected) {
                    this.focusFunction(this.rates.rates[i]);
                }
            }
        } else {
            this.focusFunction(this.componentList[0]);
        }
    }

    newRate() {
        this.newEntry = true;
        this.rowSelected = null;
        this.rateFrom = null;
        this.rateTo = null;
        this.rateMonthlyCeiling = null;
        this.rateMonthlyFloor = null;
        this.rateWeeklyCeiling = null;
        this.rateWeeklyFloor = null;
        this.resetForm();
        setTimeout(() => document.getElementById('rteFrom').focus(), 0.1);
    }

    searchEntry(ev:Event):void {
        //console.log((<HTMLTextAreaElement>ev.target).value);
        this.searchValue = (<HTMLTextAreaElement>ev.target).value.toLowerCase();
        this.componentList = this.rates.rates.filter(function( obj ) {
            return (obj['periodStart'].toString().toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1 || obj['periodEnd'].toString().toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1);
        });
    }

}
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HTTPService } from './services/http.service';
import { BaseComponent } from './base.component';

import { JwtHelperService } from '@auth0/angular-jwt';
import { NgxCaptchaModule } from 'ngx-captcha';

@Component({
  selector: 'forgotpassword',
  templateUrl: './forgotpassword.component.html',
})
export class ForgotpasswordComponent extends BaseComponent implements OnInit {
	jwtHelper: JwtHelperService = new JwtHelperService();

	userId: string;
	emailAddress: string;

	captchaInput:any;

	@ViewChild('captcha') captcha: any;

	constructor (
		private router: Router,
		private _httpService: HTTPService,
		) {
		super();
	}

	ngOnInit() {
 	}

	onSubmit() { 
		this.disabled = true;
		this.message = "submitting.........";
		var obj = { 
			"username":this.userId, 
			"emailAddress":this.emailAddress
		};
		this._httpService.authPromise('user/password/reset', obj)
		.then(
				(data) => {this.returnData = data ; this.onResponce();}
			);
	}

	onResponce() {
		this.captcha.reCaptchaApi.reset();
		if (this.returnData.message == "reforge successful") {
			this.active = false;
		} else {
			this.message = "Information provided is incorrect.";
			this.disabled = false;
			this.resetForm();
		}
	}

	handleSuccess($event) {
		this.captchaInput = true;
	}
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BaseSelectComponent } from '../base.select.component';

import { Schedule } from '../services/elements';
import { HTTPService } from '../services/http.service';
import { ScheduleService } from '../services/elements.service';
import { UserService } from '../services/user.service';

import {saveAs as importedSaveAs} from "file-saver";

@Component({
    selector: 'schedule-view',
    templateUrl: './schedule-view.component.html'
})
export class ScheduleViewComponent extends BaseSelectComponent {
    @Input() loaded: boolean = false;
    @Input() schedules: any;
    @Input() nonSubmission: any;
    @Input() EmployerViewer: boolean = true;
    @Input() PostEntryViewer: boolean = false;
    @Output() scheduleRemoving: EventEmitter<any> = new EventEmitter();
    returnFile: any;
    order:string = 'ascending';
    columnSelected:string = null;
    rowSelected:string;

    monthlyEmployees:number = 0;
    monthlyActualEarnings:number = 0;
    monthlyInsurableEarnings:number = 0;
    monthlyContributionsPayment:number = 0;
    weeklyEmployees:number = 0;
    weeklyActualEarnings:number = 0;
    weeklyInsurableEarnings:number = 0;
    weeklyContributionsPayment:number = 0;

    paymentSurcharge:number = 0;
    paymentInterest:number = 0;

    Paid:boolean = false;

    constructor( private _httpService: HTTPService, 
        public _ScheduleService:ScheduleService, 
        public user:UserService
        ) 
    {
        super();
    }

    ngOnInit() {
        this.setSummary();
     }

    setSummary(schedule:any = this._ScheduleService.schedule) {
        var mEmployees = 0;
        var mActualEarnings = 0;
        var mInsurableEarnings = 0;
        var mContributionsPayment = 0;
        var wEmployees = 0;
        var wActualEarnings = 0;
        var wInsurableEarnings = 0;
        var wContributionsPayment = 0;
        if (schedule.schedulesMonthly){
            for (var i=0; i < schedule.schedulesMonthly.length; i++) {
                mEmployees ++;
                mActualEarnings += Number(schedule.schedulesMonthly[i]['actualEarnings']);
                mInsurableEarnings += Number(schedule.schedulesMonthly[i]['insurableEarnings']);
                mContributionsPayment += Number(schedule.schedulesMonthly[i]['employerContribution']) + Number(schedule.schedulesMonthly[i]['employeeContribution']);
            }
        }
        if (schedule.schedulesWeekly) {
            for (var i=0; i < schedule.schedulesWeekly.length; i++) {
                wEmployees ++;
                wActualEarnings += Number(schedule.schedulesWeekly[i]['weekOne']) + Number(schedule.schedulesWeekly[i]['weekTwo']) + Number(schedule.schedulesWeekly[i]['weekThree']) +Number(schedule.schedulesWeekly[i]['weekFour']) +Number(schedule.schedulesWeekly[i]['weekFive']);
                wInsurableEarnings += Number(schedule.schedulesWeekly[i]['insurableEarnings']);
                wContributionsPayment += Number(schedule.schedulesWeekly[i]['employerContribution']) + Number(schedule.schedulesWeekly[i]['employeeContribution']);
            }
        }
        this.monthlyEmployees = mEmployees;
        this.monthlyActualEarnings = mActualEarnings;
        this.monthlyInsurableEarnings = mInsurableEarnings;
        this.monthlyContributionsPayment = mContributionsPayment;
        this.weeklyEmployees = wEmployees;
        this.weeklyActualEarnings = wActualEarnings;
        this.weeklyInsurableEarnings = wInsurableEarnings;
        this.weeklyContributionsPayment = wContributionsPayment;
        this.paymentSurcharge = Number(schedule.surcharge);
        this.paymentInterest = Number(schedule.interest);
        //navigate to the summary tab crude
        if (document.getElementById("component")){
            if (document.getElementById("monthlyTab")) {
                document.getElementById("monthlyTab").classList.remove('active');
            }
            if (document.getElementById("weeklyTab")) {
                document.getElementById("weeklyTab").classList.remove('active');
            }
            document.getElementById("reportTab").classList.add('active');
            document.getElementById("monthly").classList.remove('active');
            document.getElementById("weekly").classList.remove('active');
            document.getElementById("report").classList.add('active')
            document.getElementById("report").classList.remove('out');;
            document.getElementById("report").classList.add('in');
        }
    }

    sortMonthly( select:string ): void {
        if (select != this.columnSelected) {
            this.order = 'ascending';
            this.columnSelected = select;
        } else if (this.order == 'ascending') {
            this.order = 'descending';
        } else {
            this.columnSelected = null;
        }
        if (this.columnSelected) {
            if (this.order == 'ascending') {
                this.schedules.schedulesMonthly.sort( function(name1:any, name2:any) {
                    if ( name1[select] < name2[select] ){
                        return -1;
                    }else if( name1[select] > name2[select] ){
                        return 1;
                    }else{
                        return 0;  
                    }
                });
            } else {
                this.schedules.schedulesMonthly.sort( function(name1:any, name2:any) {
                    if ( name1[select] > name2[select] ){
                        return -1;
                    }else if( name1[select] < name2[select] ){
                        return 1;
                    }else{
                        return 0;  
                    }
                });
            }
        }
    }

    sortWeekly( select:string ): void {
        if (select != this.columnSelected) {
            this.order = 'ascending';
            this.columnSelected = select;
        } else if (this.order == 'ascending') {
            this.order = 'descending';
        } else {
            this.columnSelected = null;
        }
        if (this.columnSelected) {
            if (this.order == 'ascending') {
                this.schedules.schedulesWeekly.sort( function(name1:any, name2:any) {
                    if ( name1[select] < name2[select] ){
                        return -1;
                    }else if( name1[select] > name2[select] ){
                        return 1;
                    }else{
                        return 0;  
                    }
                });
            } else {
                this.schedules.schedulesWeekly.sort( function(name1:any, name2:any) {
                    if ( name1[select] > name2[select] ){
                        return -1;
                    }else if( name1[select] < name2[select] ){
                        return 1;
                    }else{
                        return 0;  
                    }
                });
            }
        }
    }

    saveFile() {
        var obj = {
            "id": this.schedules.id
        }
        this._httpService.genericDownloadPromise('schedule/download/reportdetailed', obj)
        .then(
                (data) => {this.returnFile = data ; this.onResponceSave();}
            );
    }

    nimsFIle() {
        var obj = {
            "id": this.schedules.id
        }
        this._httpService.genericDownloadPromise('schedule/download/scheduletext', obj)
        .then(
                (data) => {this.returnFile = data ; this.onNimsResponceSave();}
            );
    }

    onResponceSave() {
        var blob = new Blob([this.returnFile], {type: 'application/pdf'});
        importedSaveAs(blob, 'Report '+this.getMonth(this.schedules.month)+" "+this.schedules.year+" #"+this.schedules.scheduleNumber+'.pdf');
        //var downloadUrl= window.URL.createObjectURL(blob);
        //window.open(downloadUrl);
    }

    onNimsResponceSave() {
        var blob = new Blob([this.returnFile], {type: 'text/plain'});
        importedSaveAs(blob, 'Nims Upload '+this.getMonth(this.schedules.month)+" "+this.schedules.year+" #"+this.schedules.scheduleNumber+'.txt');
        //var downloadUrl= window.URL.createObjectURL(blob);
        //window.open(downloadUrl);
    }

    saveSummaryFile() {
        var obj = {
            "id": this.schedules.id
        }
        this._httpService.genericDownloadPromise('schedule/download/reportsummary', obj)
        .then(
                (data) => {this.returnFile = data ; this.onResponceSaveSummary();}
            );
    }

    onResponceSaveSummary() {
        var blob = new Blob([this.returnFile], {type: 'application/pdf'});
        importedSaveAs(blob, 'Summary Report '+this.getMonth(this.schedules.month)+" "+this.schedules.year+" #"+this.schedules.scheduleNumber+'.pdf');
        //var downloadUrl= window.URL.createObjectURL(blob);
        //window.open(downloadUrl);
    }

    deleteSchedule() {
        this.scheduleRemoving.emit(this.schedules);
    }

    payment() {

    }
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HTTPService } from './services/http.service';
import { BaseComponent } from './base.component';


@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent extends BaseComponent implements OnInit {
	key: string;
	oldPassword:string;
	newPassword: string;
	confirmationPassword: string;
	changed = false;

	constructor (
		private router: Router,
		private _httpService: HTTPService,
		) {
		super();
	}

	ngOnInit() {
 	}

 	onSubmit() { 
		this.disabled = true;
		this.message = "submitting.........";
		var obj =
        {
            "currentPassword":this.oldPassword,
			"newPassword":this.newPassword
        };
		this._httpService.genericPromise('user/password/change', obj)
		.then(
				(data) => {this.returnData = data ; this.onResponce();}
			);
	}

	onResponce() {
		if (this.returnData.message == "reforge successful") {
			this.active = false;
			this.changed = true;
		} else {
			this.message = this.returnData.message;
			this.disabled = false;
			this.resetForm();
		}
	}
}

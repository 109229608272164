import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class HTTPService {
	private url = 'https://calth.nissvg.org/';  // URL to web api




	constructor(private http: HttpClient) { }

	genericPromise(routeReference:string, search:any = null): Promise<any> {
		var myJSON = JSON.stringify(search);
		let header = new HttpHeaders();
		header = header.set('x-Auth-Token', sessionStorage.getItem("id_token"));
		return this.http.post(this.url+routeReference, myJSON, {headers: header})
			.toPromise()
		    .then(res => res)
		    .catch(this.handleError);
	}

	genericDownloadPromise(routeReference:string, search:any = null): Promise<any> {
		var myJSON = JSON.stringify(search);
		let header = new HttpHeaders();
		header = header.set('x-Auth-Token', sessionStorage.getItem("id_token"));
		return this.http.post(this.url+routeReference, myJSON, { headers:header, responseType: 'blob'})
			.toPromise()
		    .then(res => res)
		    .catch(this.handleError);
	}

	authPromise(routeReference:string, search:any = null): Promise<any> {
		var myJSON = JSON.stringify(search);
		return this.http.post(this.url+routeReference, myJSON)
			.toPromise()
		    .then(res => res)
		    .catch(this.handleError);
	}

	retrievePromise(routeReference:string, search:any = null): Promise<any> {
		var myJSON = JSON.stringify(search);
		let header = new HttpHeaders();
		header = header.set('x-Auth-Token', sessionStorage.getItem("id_token"));
		return this.http.post(this.url+routeReference+'/retrieve/any', myJSON, {headers: header})
			.toPromise()
		    .then(res => res)
		    .catch(this.handleError);
	}

	reforgePromise(routeReference:string, search:any = null): Promise<any> {
		var myJSON = JSON.stringify(search);
		let header = new HttpHeaders();
		header = header.set('x-Auth-Token', sessionStorage.getItem("id_token"));
		return this.http.post(this.url+routeReference+'/reforge/any', myJSON, {headers: header})
			.toPromise()
		    .then(res => res)
		    .catch(this.handleError);
	}

	private handleError(error: any) {
		if (error.status == 401) {
			return error
		} else if (error.status == 403) {
			alert('Your account is Diactivated');
		} else {
			//console.error('An error occurred', error); // for demo purposes only
			alert('An error occurred:'+ error);
    		return Promise.reject(error.message || error);
		}
  	}

	filePost(formData:any) {
		let header = new HttpHeaders();
		header = header.set('x-Auth-Token', sessionStorage.getItem("id_token"));
		return this.http.post(this.url+'schedule/upload', formData, {headers: header})
			.toPromise()
			.then(res => res)
			.catch(this.handleError);
	}


}
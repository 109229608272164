import { Component, OnInit, ApplicationRef } from '@angular/core';

import { HTTPService } from '../services/http.service';
import { MessagingService } from '../services/messaging.service';
import { GroupsService, MessageService, UsersService, InsuredUsersService } from '../services/elements.service';
import { BaseEntryComponent } from '../base.entry.component';

import {saveAs as importedSaveAs} from "file-saver";
declare var jQuery:any;

@Component({
    selector: 'admin-message',
    templateUrl: './admin-message.component.html',
})
export class AdminMessageComponent extends BaseEntryComponent implements OnInit {
    order:string = 'ascending';
    selected:string = null;

    returnFile: any;

    usrUserName:string;
    usrName:string;
    usrid;

    //values in form
    globalSubject:string;
    globalMessage:string;
    groupSubject:string;
    groupMessage:string;
    singleSubject:string;
    singleMessage:string;

    group:any;

    messageObject:any;
    loadedInusred:boolean = false;


    constructor(private _httpService: HTTPService, 
        private applicationRef: ApplicationRef, 
        private groups: GroupsService, 
        public _MessageService: MessageService, 
        private users: UsersService, 
        private _InsuredUsersService: InsuredUsersService,
        private _messagingService: MessagingService 
        ) {
        super();
    }

    ngOnInit(): void {
        this.loadedInusred = false;
        var obj =
        {
            "type":"I"
        };
        this._httpService.retrievePromise('user', obj)
        .then(
                (data) => {this.returnData = data ; this.onInsuredUserResponce();}
            )
        .catch(
                (error) => this.handleError(error)
            );
    }

    focusFunction(row:any):void {
        if(row != undefined) {
            this.usrUserName = row['username'];
            this.usrName = row['name'];
            this.usrid = row['id']
        }
    }

    onSubmit(type) {
        switch (type) {
            case 'Global':
                this.messageObject =
                {
                    "subject": this.globalSubject,
                    "message": this.globalMessage,
                };
                break;
            case 'Group':
                this.messageObject =
                {
                    "subject": this.groupSubject,
                    "message": this.groupMessage,
                    "groupId": this.group
                };
                break;

            case 'Single':
                this.messageObject =
                {
                    "subject": this.singleSubject,
                    "message": this.singleMessage,
                    "userId": this.usrid
                };
                break;
        }
        this.disabled = true;
        this._messagingService.createMessageAlert("Saving.........", false);
        this._httpService.genericPromise('message/reforge/notify', this.messageObject)
        .then(
                (data) => {this.returnData = data ; this.onResponceSave();}
            );
    }

    onResponceSave() {
        this.newEntry = false;
        this.disabled = false;
        if (this.returnData.message == "reforge successful") {
            this._messagingService.createMessageAlert("Save successful.", true);
            this._MessageService.messages.push(this.messageObject);
        } else {
            this._messagingService.createMessageAlert(this.returnData.message, true);
        }
    }

    searchEntry(ev:Event):void {
        this.componentList = this._InsuredUsersService.users.filter(function( obj ) {
            return (obj['username'].toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1 || obj['name'].toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1);
        });
    }

    onInsuredUserResponce() {
        this.loadedInusred = false;
        if (this.returnData.message == "retrieval successful") {
            this._InsuredUsersService.users = this.returnData.data;
            this.focusFunction(this._InsuredUsersService.users[0]);
            this.setComponentList(this._InsuredUsersService.users);
            this.loadedInusred = true;
        } else {
        }
    }

    onCancel() {
        this.singleSubject = ''; 
        this.singleMessage = '';
        this.usrid = '';
        this.groupSubject = '';
        this.groupMessage = '';
        this.group = '';
        this.globalSubject = '';
        this.globalMessage = '';
        this.active = false;
        setTimeout(() => this.active = true, 0);
    }


}
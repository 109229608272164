import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReturnData } from './services/return.data';


@Component({
  selector: 'change-password',
  templateUrl: './help.component.html',
})
export class HelpComponent implements OnInit {

	constructor (
		private router: Router
		) {
	}

	ngOnInit() {
 	}
}

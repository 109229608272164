import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RegistrationComponent } from './registration.component';
import { RegistrationPostComponent } from './registration-post.component';
import { ForgotpasswordComponent } from './forgotpassword.component';
import { ForgotpasswordResetComponent } from './forgotpassword-reset.component';
import { ChangePasswordComponent }     from './change-password.component';
import { DashboardComponent }   from './dashboard.component';
import { LoginComponent } from './login.component';
import { UserScheduleComponent } from './user-management/user-schedule.component';
import { UserScheduleUploadComponent } from './user-management/user-schedule-upload.component';
import { UserScheduleViewComponent } from './user-management/user-schedule-view.component';
import { UserScheduleMultiComponent } from './user-management/user-schedule-multi.component';
import { UserEmployeeComponent } from './user-management/user-employee.component';

import { AdminContributionComponent } from './admin-management/admin-contribution.component';
import { AdminUserComponent } from './admin-management/admin-user.component';
import { AdminGroupComponent } from './admin-management/admin-group.component';
import { AdminEmployerComponent } from './admin-management/admin-employer.component';
import { AdminRegistrationComponent } from './admin-management/admin-registration.component';
import { AdminSalaryComponent } from './admin-management/admin-salary.component';
import { AdminScheduleComponent } from './admin-management/admin-schedule.component';
import { AdminPensionComponent } from './admin-management/admin-pension.component';
import { AdminDownloadComponent } from './admin-management/admin-download.component';
import { AdminMessageComponent } from './admin-management/admin-message.component';
import { AdminInsuredRegistrationComponent } from './admin-management/admin-insuredregistration.component';
import { AdminEventComponent } from './admin-management/admin-event.component';
import { AdminReconciliationComponent } from './admin-management/admin-reconciliation.component';
import { AdminNonSubmissionComponent } from './admin-management/admin-non-submission.component';
import { AdminVoidSubmissionComponent } from './admin-management/admin-void-submission.component';
import { SelfEmployedRegistrationComponent } from './admin-management/self-employed/self-employed-registration/self-employed-registration.component';

import { PageNotFoundComponent } from './page.not.found.component';

import { HelpComponent } from './help.component';

import { PaymentComponent } from './payment/payment.component';

import { AuthGuard, AuthGuardAdmin, AuthGuardEmployer, AuthGuardLoggedOut, AuthGuardForceLoggedOut, AuthGuardSchedule } from './services/guards.service';

const routes: Routes = [
	{ path: '', redirectTo: '/login', pathMatch: 'full' },
	{ path: 'registration',  component: RegistrationComponent, canActivate: [AuthGuardLoggedOut], pathMatch: 'full' },
	{ path: 'registration/post/:key',  component: RegistrationPostComponent, canActivate: [AuthGuardForceLoggedOut], pathMatch: 'full' },
	{ path: 'forgotpassword',  component: ForgotpasswordComponent, canActivate: [AuthGuardLoggedOut], pathMatch: 'full' },
	{ path: 'forgotpassword/post/:key',  component: ForgotpasswordResetComponent, canActivate: [AuthGuardForceLoggedOut], pathMatch: 'full' },
	{ path: 'settings/change_password',  component: ChangePasswordComponent, canActivate: [AuthGuard], pathMatch: 'full' },
	{ path: 'dashboard',  component: DashboardComponent, canActivate: [AuthGuard], pathMatch: 'full' },
	{ path: 'login',  component: LoginComponent, canActivate: [AuthGuardLoggedOut], pathMatch: 'full' },
	{ path: 'management/schedules',  component: UserScheduleViewComponent, canActivate: [AuthGuardEmployer], pathMatch: 'full' },
	{ path: 'entry/schedules_multi',  component: UserScheduleMultiComponent, canActivate: [AuthGuardSchedule], pathMatch: 'full' },
	{ path: 'entry/schedules',  component: UserScheduleComponent, canActivate: [AuthGuardSchedule], pathMatch: 'full' },
	{ path: 'entry/upload',  component: UserScheduleUploadComponent, canActivate: [AuthGuardEmployer], pathMatch: 'full' },
	{ path: 'schedule/payment',  component: PaymentComponent, canActivate: [AuthGuard], pathMatch: 'full' },
	{ path: 'management/employees',  component: UserEmployeeComponent, canActivate: [AuthGuardEmployer], pathMatch: 'full' },
	{ path: 'admin_management/users',  component: AdminUserComponent, canActivate: [AuthGuardAdmin], pathMatch: 'full' },
	{ path: 'admin_management/groups',  component: AdminGroupComponent, canActivate: [AuthGuardAdmin], pathMatch: 'full' },
	{ path: 'admin_management/employers',  component: AdminEmployerComponent, canActivate: [AuthGuardAdmin], pathMatch: 'full' },
	{ path: 'admin_management/registrations',  component: AdminRegistrationComponent, canActivate: [AuthGuardAdmin], pathMatch: 'full' },
	{ path: 'admin_management/salary_rates',  component: AdminSalaryComponent, canActivate: [AuthGuardAdmin], pathMatch: 'full' },
	{ path: 'admin_management/contribution_rates',  component: AdminContributionComponent, canActivate: [AuthGuardAdmin], pathMatch: 'full' },
	{ path: 'admin_management/schedules',  component: AdminScheduleComponent, canActivate: [AuthGuardAdmin], pathMatch: 'full' },
	{ path: 'admin_management/pensions',  component: AdminPensionComponent, canActivate: [AuthGuardAdmin], pathMatch: 'full' },
	{ path: 'admin_management/downloads',  component: AdminDownloadComponent, canActivate: [AuthGuardAdmin], pathMatch: 'full' },
	{ path: 'admin_management/non_submission',  component: AdminNonSubmissionComponent, canActivate: [AuthGuardAdmin], pathMatch: 'full' },
	{ path: 'admin_management/void_submission',  component: AdminVoidSubmissionComponent, canActivate: [AuthGuardAdmin], pathMatch: 'full' },
	{ path: 'admin_app_management/messages',  component: AdminMessageComponent, canActivate: [AuthGuardAdmin], pathMatch: 'full' },
	{ path: 'admin_app_management/insured_registrations',  component: AdminInsuredRegistrationComponent, canActivate: [AuthGuardAdmin], pathMatch: 'full' },
	{ path: 'admin_app_management/event',  component: AdminEventComponent, canActivate: [AuthGuardAdmin], pathMatch: 'full' },
	{ path: 'admin_app_management/reconciliation',  component: AdminReconciliationComponent, canActivate: [AuthGuardAdmin], pathMatch: 'full' },
	{ path: 'self_employed/registration',  component: SelfEmployedRegistrationComponent, canActivate: [AuthGuardAdmin], pathMatch: 'full' },
	{ path: 'help',  component: HelpComponent, canActivate: [AuthGuard], pathMatch: 'full' },
	{ path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

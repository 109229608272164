import { Component, OnInit } from '@angular/core';

import { BaseEntryComponent } from '../../../base.entry.component';

import { HTTPService } from '../../../services/http.service';
import { UserService } from '../../../services/user.service';
import { MessagingService } from '../../../services/messaging.service';

@Component({
  selector: 'app-self-employed-registration',
  templateUrl: './self-employed-registration.component.html'
})
export class SelfEmployedRegistrationComponent extends BaseEntryComponent implements OnInit {
	page:number;
	count:number;
	searching:boolean;
	public state: string;
	
    constructor(
		public user:UserService,
		private _httpService: HTTPService,
		private _messagingService: MessagingService
		) 
	{ 
		super();
	}
	searchEntry(ev:Event):void 
    {
    	/*if ((<HTMLTextAreaElement>ev.target).value) {
    		this.searchValue = (<HTMLTextAreaElement>ev.target).value.toLowerCase();
	        this.componentList = this.originalComponentList.filter(function( obj ) {
	            return (obj['claimNumber'].toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1);
	        });
    	}*/
    }

	ngOnInit() 
	{
		this.changeState('select');
		this.loaded = false;
		this.disabled = true;
		this.searching = false;
		this.page = 1;
		this.searchValue='';
		this.list();
	}

	/**
	 * List all TUB(BUT) claims
	 */
	list()
	{
		this.disabled = true;
		this._httpService.genericPromise('register/selfemployed/retrieve/any', {} )
			.then(
				(data) => {this.returnData = data ; this.onResponce();}
			).catch(this.handleError);
	}

	/**
	 * Actions to take after a list is returned
	 */
    onResponce() 
    {
    	this.disabled = false;
		if (this.returnData.message == "retrieval successful") {
			this.count = this.returnData.data.count;
			this.setComponentList(this.returnData.data.list);
		} else {
			this._messagingService.createMessageAlert(this.returnData.message, true);
		}
		//this.postSort();
		this.loaded = true;
	}

	/**
	 * Chnage the state of the page
	 */
	changeState(newState) 
	{
    	this.state = newState;
    }

}

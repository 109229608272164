import { Component, OnInit, ApplicationRef, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { ScheduleViewComponent } from '../components/schedule-view.component';

import { ScheduleService, NonSubmissionListService, ScheduleListService, UploadService } from '../services/elements.service';

import { Schedule, NonSubmission } from '../services/elements';
import { HTTPService } from '../services/http.service';
import { UserService } from '../services/user.service';
import { MessagingService } from '../services/messaging.service';

import { BaseComponent } from '../base.component';

declare var jQuery:any;

@Component({
  selector: 'user-schedule-view',
  templateUrl: './user-schedule-view.component.html',
})
export class UserScheduleViewComponent extends BaseComponent implements OnInit {
    @ViewChild('fileInput') fileInput:ElementRef;
    public scheduleIndex:any;
    @ViewChild('scheduleView') scheduleView: ScheduleViewComponent;
    selectSchedule:any;
    selectNonSubmission:any;
    schedulesList: Schedule[];
    nonSchedulesList: NonSubmission[];
    nonSubmission: any;
    yearRange:any[];
    currentYear:number;
    currentMonth:number;

    existingMonth:number = 0;
    existingYear:number = 0;
    existingSchedule:number = 0;

    selectedScheduleYear:number;
    selectedScheduleMonth:number;

    selectedNonScheduleYear:number;
    selectedNonScheduleMonth:number;
    selectedNonSchedulereason:string;
    selectedNonScheduleOtherreason:string;
    selectedNonScheduleDeclareCtrlVal:any;

    resetList:boolean = false;

    popupScheduleMessage:string;
    previousDeclaration:boolean;
    previousSchedule:boolean;


    deleteSchedule:Schedule = new Schedule();
    deleteReason:string;

    canUpload:boolean = true;


    constructor(public _user:UserService, 
        private _httpService: HTTPService, 
        private router: Router, 
        private applicationRef: ApplicationRef, 
        public _ScheduleService:ScheduleService, 
        private _UploadService:UploadService, 
        private _ScheduleListService:ScheduleListService,  
        private user:UserService, 
        private _NonSubmissionListService:NonSubmissionListService, 
        private _messagingService: MessagingService
        ) {
        super();
        //get the years to submit for
        this.yearRange = []
        this.currentYear = (new Date()).getFullYear();
        this.currentMonth = (new Date()).getMonth();
        for (var i = 1987; i <= (new Date()).getFullYear()+1; i++) {
            this.yearRange.push(i);
        }
        this.selectedScheduleYear = this.currentYear;
        this.selectedScheduleMonth = this.currentMonth;
        this.selectedNonScheduleYear = this.currentYear;
        this.selectedNonScheduleMonth = this.currentMonth;

        this.deleteSchedule.month = 1;
        this.deleteSchedule.year = this.currentYear;
        this.deleteSchedule.scheduleNumber = 1;
    }

    ngOnInit(): void {
        this.schedulesList = this._ScheduleListService.schedule;
        this.nonSchedulesList = this._NonSubmissionListService.schedule;
        this.scheduleIndex = {
            years : {}
        };
        this.getScheduleIndex();
    }

    setSchedule(schedule:any = -1):any {
        //If function called without an ID, create a new schedule
        this.scheduleView.loaded = false;
        this.selectSchedule = null;
        this.selectNonSubmission = null;
        if (schedule.id === -1) {
            return new Schedule();
        } else if (schedule.nonSubmission) {
            //seach for Nonsubmission by ID
            for(var i = 0;i<this.nonSchedulesList.length;i++) {
                if (this.nonSchedulesList[i]['id'] == schedule.id) {
                    this.selectNonSubmission = this.nonSchedulesList[i];
                }
            }
        } else {
            //load schedule
            var obj4 = {
                "search": [
                    {
                        "column":"id",
                        "operator":"=",
                        "value":schedule.id
                    }
                ]
            }
            this._httpService.retrievePromise('schedule', obj4)
            .then(
                    (data) => {this.returnData = data ; this.onScheduledetailLoad();}
                );
        }
    }

    onScheduledetailLoad() {
        if (this.returnData.message == "retrieval successful") {
            this.selectSchedule = this.returnData.data[0];
            this.existingMonth = this.returnData.data[0].month;
            this.existingYear = this.returnData.data[0].year;
            this.existingSchedule = this.returnData.data[0].scheduleNumber;
            this.scheduleView.setSummary(this.selectSchedule);
        } else {
        }
        this.scheduleView.loaded = true;
    }

    removeSchedule(schedule:any = -1) {
        this.selectedScheduleYear = this.currentYear;
        this.selectedScheduleMonth = 1;
        this.resetList = true;
        setTimeout(() => this.resetList = false, 0);
        for (var i=0; i < this.schedulesList.length; i++) {
            if (this.schedulesList[i]['id'] == schedule) {
                this.schedulesList.splice(i,1);
            }
        }
        this.scheduleIndex = {
            years : {}
        };
        this.getScheduleIndex();
        this._messagingService.createMessageAlert("Your schedule has been removed.", true);
    }

    removingSchedule(schedule:Schedule) {
        this.deleteSchedule = schedule;
        this.deleteReason = null;
        jQuery(document.getElementById('deleteScheduleModal')).modal('show');
    }

    submitRemoveSchedule() {
        this._messagingService.createMessageAlert("Please wait while we remove the schedule.", false);
        var obj = {
            "id": this.deleteSchedule.id,
            "void": '1',
            "voidReason": this.deleteReason
        }
        this._httpService.genericPromise('schedule/void', obj)
        .then(
                (data) => {this.returnData = data ; this.onResponceDelete();}
            );
    }

    onResponceDelete() {
        if (this.returnData.message == "reforge successful") {
            this.removeSchedule(this.returnData.id);
        } else {
            this._messagingService.createMessageAlert("Error removing your Schedule.", true);
        }
    }

    getScheduleIndex() {
        for(var i = 0;i<this.schedulesList.length;i++) {
            var idLoop = this.schedulesList[i]['id'];
            var yearOfLoop = this.schedulesList[i]['year'];
            var monthOfLoop = this.schedulesList[i]['month'];
            var scheduleNumberOfLoop = this.schedulesList[i]['scheduleNumber'];
            var scheduleMethod = (this.schedulesList[i]['uploadMethod'] == 'EW')? "upload": "schedule";
            if (typeof this.scheduleIndex.years[yearOfLoop] == 'undefined') {
                this.scheduleIndex.years[yearOfLoop] = {
                    year : yearOfLoop,
                    periods : {}
                };
            }
            if (typeof this.scheduleIndex.years[yearOfLoop].periods[monthOfLoop] == 'undefined') {
                this.scheduleIndex.years[yearOfLoop].periods[monthOfLoop]={
                    month : monthOfLoop,
                    schedules : {}
                };
            }
            if (typeof this.scheduleIndex.years[yearOfLoop].periods[monthOfLoop].schedules[scheduleNumberOfLoop] == 'undefined') {
                this.scheduleIndex.years[yearOfLoop].periods[monthOfLoop].schedules[scheduleNumberOfLoop]={
                    id : idLoop,
                    number : scheduleNumberOfLoop,
                    type: scheduleMethod
                };
            }
        }

        for(var i = 0;i<this.nonSchedulesList.length;i++) {
            var idLoop = this.nonSchedulesList[i]['id'];
            var yearOfLoop = this.nonSchedulesList[i]['year'];
            var monthOfLoop = this.nonSchedulesList[i]['month'];
            var scheduleNumberOfLoop = 1;
            if (typeof this.scheduleIndex.years[yearOfLoop] == 'undefined') {
                this.scheduleIndex.years[yearOfLoop] = {
                    year : yearOfLoop,
                    periods : {}
                };
            }
            if (typeof this.scheduleIndex.years[yearOfLoop].periods[monthOfLoop] == 'undefined') {
                this.scheduleIndex.years[yearOfLoop].periods[monthOfLoop]={
                    month : monthOfLoop,
                    schedules : {}
                };
            }
            if (typeof this.scheduleIndex.years[yearOfLoop].periods[monthOfLoop].schedules[scheduleNumberOfLoop] == 'undefined') {
                this.scheduleIndex.years[yearOfLoop].periods[monthOfLoop].schedules[scheduleNumberOfLoop]={
                    id : idLoop,
                    number : scheduleNumberOfLoop,
                    type: 'nonSubmission'
                };
            }
        }
    }

    generateSchedule(fromExisting:boolean = false) {
        this._messagingService.createMessageAlert("Please wait while we generate the schedule.", false);
        if (fromExisting) {
            this._ScheduleService.generate(this.selectedScheduleYear, this.selectedScheduleMonth, this.selectSchedule, true);
        } else {
            this._ScheduleService.generate(this.selectedScheduleYear, this.selectedScheduleMonth);
        }
        this._ScheduleService.schedule.registrationNumber = this.user.user.aud;
        this._httpService.genericPromise('schedule/save/true', this._ScheduleService.schedule)
        .then(
                (data) => {this.returnData = data ; this.onResponceSave();}
            );
    }

    contineSchedule() {
        if (this._UploadService.schedules.length >= 1) {
            this.router.navigate(['/entry/upload']);
        } else {
            this.router.navigate(['/entry/schedules']);
        }
    }

    onResponceSave() {
        if (this.returnData.message == "reforge successful") {
            this._messagingService.hideMessageAlert();
            this._ScheduleService.generate(this.returnData.record.year, this.returnData.record.month, this.returnData.record);
            this.router.navigate(['/entry/schedules']);
        } else {
            this._messagingService.createMessageAlert("There was an error generating your schedule. Please contact Administrator", true);
        }
    }

    clickFileUpload():void {
        //this.fileInput.click();
        let el: HTMLElement = this.fileInput.nativeElement as HTMLElement;
        el.click();
    }

    upload() {
        this._messagingService.createMessageAlert("Please wait while we prepare the schedule.", false);
        let inputEl: HTMLInputElement = this.fileInput.nativeElement;
        let fileCount: number = inputEl.files.length;
        let formData = new FormData();
        if (fileCount > 1) { // a file was selected
            for (let i = 0; i < fileCount; i++) {
                formData.append('value'+i, inputEl.files.item(i));
            }
        } else if (fileCount == 1) {
            formData.append('value', inputEl.files.item(0))
        }
        this._httpService.filePost( formData)
            .then(
                (data) => {this.returnData = data ; this.onUploadSave();}
            );
    }

    onUploadSave() {
        if (this.returnData.message == "reforge successful") {
            var obj2 = {
                "search": [
                        {
                            "column":"registrationNumber",
                            "operator":"=",
                            "value":this._user.user.aud
                        },
                        {
                            "append":"and",
                            "column":"void",
                            "operator":"!=",
                            "value":"1"
                        },
                        {
                            "append":"and",
                            "column":"submissionDate",
                            "operator":"=",
                            "value":"NULL"
                        }
                    ]
            }
            this._httpService.retrievePromise('schedule', obj2)
            .then(
                    (data) => {this.returnData = data ; this.uploadLoadResponce();}
                )
        } else {
            this.canUpload = false;
            setTimeout(() => this.canUpload = true, 0);
            this._messagingService.createMessageAlert("There seems to be an error with your upload file.", true);
        }
    }

    uploadLoadResponce(){
        if (this.returnData.message == "retrieval successful") {
            this._messagingService.hideMessageAlert();
            this._UploadService.schedules = this.returnData.data;
            this._ScheduleService.generate(this.returnData.data[0].year, this.returnData.data[0].month, this.returnData.data[0]);
            this.router.navigate(['/entry/upload']);
        } else {
        }
    }

    saveNonSubmit() {
        this.nonSubmission =
        {
            registrationNumber: this.user.user.aud,
            year: this.selectedNonScheduleYear,
            month: Number(this.selectedNonScheduleMonth),
            reason: (this.selectedNonSchedulereason == 'Other'?this.selectedNonScheduleOtherreason:this.selectedNonSchedulereason),
        };
        this._httpService.genericPromise('nonsubmission/submit', this.nonSubmission)
        .then(
                (data) => {this.returnData = data ; this.onNonSubmitSave();}
            );
    }

    onNonSubmitSave() {
        if (this.returnData.message == "reforge successful") {
            this.nonSubmission.id = this.returnData.id;
            this._NonSubmissionListService.schedule.push(this.nonSubmission);
            this.scheduleIndex = {
                years : {}
            };
            this.getScheduleIndex();
        } else {
        }
    }

    generateFromExisting(){
        if (this.selectSchedule == undefined) {
            this._messagingService.createMessageAlert("You must select a valid Schedule to generate from.", true);
        } else {
            jQuery(document.getElementById('generateFromExistingModal')).modal('show');
        }
    }

    localNumber(n:any):number {
        return Number(n);
    }

    checkExistingSchedule(year:any, month:any) {
        this.popupScheduleMessage = undefined;
        this.previousDeclaration = false;
        this.previousSchedule = false;


        for(var i = 0;i<this._NonSubmissionListService.schedule.length;i++) {
            if (this._NonSubmissionListService.schedule[i].year == year && this._NonSubmissionListService.schedule[i].month == month) {
                this.popupScheduleMessage = 'A non submission declaration already exists for this period';
                this.previousDeclaration = true;
            }
        }
        for(var i = 0;i<this._ScheduleListService.schedule.length;i++) {
            if (this._ScheduleListService.schedule[i].year == year && this._ScheduleListService.schedule[i].month == month) {
                this.popupScheduleMessage = 'A scheduled already exists for this period';
                this.previousSchedule = true;
            }
        }
    }

}
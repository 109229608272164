import { Injectable }    from '@angular/core';

@Injectable()
export class MenuService {
	private menus = [
		{
			title : 'Management',
			value : 'management',
			visable: false,
			subMenus : [
				{title: 'Employees', value: 'employees', role:"ROLE_USER"},
				{title: 'Schedules', value: 'schedules', role:"ROLE_USER"}
			]
		},
		{
			title : 'ESubmit',
			value : 'admin_management',
			visable: false,
			subMenus : [
				{title: 'Users', value: 'users', role:"ROLE_Admin_64"},
				{title: 'Groups', value: 'groups', role:"ROLE_Admin_128"},
				{title: 'Pensions Periods', value: 'pensions', role:"ROLE_Admin_256"},
				{title: 'Employers', value: 'employers', role:"ROLE_Admin_1"},
				{title: 'Contribution Rates', value: 'contribution_rates', role:"ROLE_Admin_2"},
				{title: 'Salary Rates', value: 'salary_rates', role:"ROLE_Admin_4"},
				{title: 'Employer Registrations', value: 'registrations', role:"ROLE_Admin_16"},
				{title: 'Schedules', value: 'schedules', role:"ROLE_Admin_32"},
				{title: 'Downloads', value: 'downloads', role:"ROLE_Admin_512"},
				{title: 'Non Submission', value: 'non_submission', role:"ROLE_Admin_512"},
				{title: 'Void Submission', value: 'void_submission', role:"ROLE_Admin_512"},
			]
		},
		{
			title : 'My NIS app',
			value : 'admin_app_management',
			visable: false,
			subMenus : [
				{title: 'Insured Registrations', value: 'insured_registrations', role:"ROLE_Admin_16"},
				{title: 'Messages', value: 'messages', role:"ROLE_Admin_1024"},
				{title: 'Events', value: 'event', role:"ROLE_Admin_1024"},
				{title: 'Reconciliation', value: 'reconciliation', role:"ROLE_Admin_2048"}
			]
		},
		{
			title : 'Settings',
			value : 'settings',
			visable: false,
			subMenus : [
				{title: 'Change Password', value: 'change_password', role:"ROLE_USER"}
			]
		},
		/*{
			title : 'Self Employed',
			value : 'self_employed',
			visable: false,
			subMenus : [
				{title: 'Registration', value: 'registration', role:"ROLE_Admin_64"}
			]
		}*/
	];

	constructor() {
	}

	setMenu(type:string) 
	{
		if ( type == "A" ) {
			this.menus[0].visable = false;
			this.menus[1].visable = true;
			this.menus[2].visable = true;
			//this.menus[4].visable = true;
		} else {
			this.menus[1].visable = false;
			this.menus[2].visable = false;
			this.menus[0].visable = true;
			//this.menus[4].visable = false;
		}
		this.menus[3].visable = true;
	}

	close()
	{
		for( var i=0; i < this.menus.length; i++) {
			this.menus[i].visable = false;
		}
	}

}
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HTTPService } from './services/http.service';
import { BaseComponent } from './base.component';

import { JwtHelperService } from '@auth0/angular-jwt';

/**
 * This is the post registration component that controls the email 
 * validation page during the email verification process of registration
 */
@Component({
  selector: 'registration-post',
  templateUrl: './registration-post.component.html',
})
export class RegistrationPostComponent extends BaseComponent implements OnInit {
	jwtHelper: JwtHelperService = new JwtHelperService();
	key: string;

	employerNumber: number;

	constructor (
		private router: Router,
		private route: ActivatedRoute,
		private _httpService: HTTPService,
		) {
		super();
	}

	ngOnInit() {
		this.key = this.route.snapshot.paramMap.get('key');
		this.message = "processing.........";
		var obj = { 
			"key":this.key, 
		};
		this._httpService.authPromise('user/finalise', obj)
		.then(
				(data) => {this.returnData = data ; this.onResponce();}
			);
	}

	onResponce() {
		this.message = this.returnData.message;
		if (this.returnData.message == "reforge successful") {
			this.message = "Your email address has been verified.";
		} else {
			this.message = "Invalid Request.";
		}
	}
}

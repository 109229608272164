import { BaseSelectComponent } from './base.select.component';

export abstract class BaseEntryComponent extends BaseSelectComponent {
    order:string = 'ascending';
    selected:string = null;
    newEntry = false;
    componentList:any[];
    columnSelected:string = null;
    originalComponentList:any[] = [];
    searchValue:string = null;
    public loaded: boolean = false;

    //Changes one property to another for sorting on multiple columns
    repurposeColumn( column1, column2) {
        for (var i=0; i < this.componentList.length; i++) {
            if (this.componentList[i][column1]) {
                this.componentList[i][column2] = this.componentList[i][column1];
            }
        }

        this.sort(column2);
    }

    setComponentList(list:any[])
    {
        this.originalComponentList = list;
        this.componentList = Object.assign([], list);
    }

    addToList(item:any) {
        this.originalComponentList.push(item);
        this.componentList.push(item);
    }

    sort( select:string ): void {
        if (select != this.columnSelected) {
            this.order = 'ascending';
            this.columnSelected = select;
        } else if (this.order == 'ascending') {
            this.order = 'descending';
        } else {
            this.columnSelected = null;
        }

        this.postSort();
    }

    postSort():void {
        if (typeof this.columnSelected !== 'undefined' && this.columnSelected) {
            let select = this.columnSelected;
            if (this.order == 'ascending') {
                this.componentList.sort( function(name1, name2) {
                    if ( name1[select] < name2[select] ){
                        return -1;
                    }else if( name1[select] > name2[select] ){
                        return 1;
                    }else{
                        return 0;  
                    }
                });
            } else {
                this.componentList.sort( function(name1, name2) {
                    if ( name1[select] > name2[select] ){
                        return -1;
                    }else if( name1[select] < name2[select] ){
                        return 1;
                    }else{
                        return 0;  
                    }
                });
            }
        } else {
            this.componentList = Object.assign([], this.originalComponentList);
            //Makes a search when the sorting is set back to the original
            if (typeof this.searchValue !== 'undefined' && this.searchValue){
                this.searchEntry( Object ({target:{value:this.searchValue}}));
            }
        }
    }

    onCancel() {
        this.newEntry = false;
        this.resetForm();
        if (this.rowSelected) {
            for (var i=0; i < this.componentList.length; i++) {
                if (this.componentList[i]['id'] == this.rowSelected) {
                    this.focusFunction(this.componentList[i]);
                }
            }
        } else {
            this.focusFunction(this.componentList[0]);
        }
    }

    abstract searchEntry(ev:Event):void;
}
import { Component, OnInit, ApplicationRef } from '@angular/core';

import { BaseEntryComponent } from '../base.entry.component';

import { HTTPService } from '../services/http.service';
import { Schedule } from '../services/elements';
import { ReconciliationService } from '../services/elements.service';
import { ReconciliationQueryService } from '../services/elements.service';
import { saveAs as importedSaveAs } from "file-saver";

@Component({
    selector: 'admin-reconciliation',
    templateUrl: './admin-reconciliation.component.html',
})
export class AdminReconciliationComponent extends BaseEntryComponent implements OnInit {
    order:string = 'ascending';
    selected:string = null;
    returnFile: any;
    selectedRecon:any;

    //private employees:Employee[];

    //values in form
    usrName: string;
    usrUserName: string;
    usrEmail: string;
    usrGroup: string;

    completedList: any[];

    loadedRecon:boolean = false;


    constructor(private _httpService: HTTPService, private applicationRef: ApplicationRef, private _ReconciliationService:ReconciliationService, private _ReconciliationQueryService:ReconciliationQueryService ) {
        super();
    }

    ngOnInit(): void {
        this.setComponentList([]);
        this.loadedRecon = false;
        var obj = {
            "sort": { 
                "id":"desc" 
            },
            "search": [
            {
              "column":"approval",
              "operator":"=",
              "value":"0" 
            }]
        };
        this._httpService.retrievePromise('reconciliation', obj)
        .then(
                (data) => {this.returnData = data; this.onResponce();}
            );
        var obj2 = {
            "sort": { 
                "id":"desc" 
            }
        };
        this._httpService.retrievePromise('reconciliation/query', obj2)
        .then(
                (data) => {this.returnData = data; this.onResponceQuery();}
            );
    }

    public onResponce() {
        this.loadedRecon = false;
        if (this.returnData.message == "retrieval successful") {
            this._ReconciliationService.reconciliations = this.returnData.data;
            this.setComponentList(this.componentList.concat(this._ReconciliationService.reconciliations));
            this.loadedRecon = true;
        } else {
        }
    }

    public onResponceQuery() {
        this.loadedRecon = false;
        if (this.returnData.message == "retrieval successful") {
            this._ReconciliationQueryService.reconciliationQuerys = this.returnData.data;
            this.setComponentList(this.componentList.concat(this._ReconciliationQueryService.reconciliationQuerys));
            this.loadedRecon = true;
        } else {
        }
    }

    focusFunction(row:any):void {
        if(row != undefined) {
            this.rowSelected = row['id'];
            this.selectedRecon = row;
        }
    }

    searchEntry(ev:Event):void {
        //console.log((<HTMLTextAreaElement>ev.target).value);
        this.searchValue = (<HTMLTextAreaElement>ev.target).value.toLowerCase();
        this.componentList = [];
        this.componentList = this.componentList.concat(this._ReconciliationQueryService.reconciliationQuerys);
        this.componentList = this.componentList.concat(this._ReconciliationService.reconciliations);
        this.componentList = this.componentList.filter(function( obj ) {
            return (obj['name'].toString().toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1);
        });
    }

    onResponceSave() {
        if (this.returnData.message == "reforge successful") {

        } else {
            //alert("Error changing employer stataus. Please contact Administrator :" +this.returnData.message);
        }
    }

    changStatus(recon:any, status:string) {
        recon['status'] = status;
        this.updateRecon(recon);
        this.rowSelected = null;
        this.selectedRecon = null;
    }

    updateRecon(recon:any) {
        if(recon['month']) {
            this._httpService.genericPromise('reconciliation/reforge/any', recon)
                .then(
                        (data) => {this.returnData = data ; this.onResponceSave();}
                    );
        } else {
                this._httpService.genericPromise('reconciliation/query/reforge/any', recon)
                .then(
                        (data) => {this.returnData = data ; this.onResponceSave();}
                    );
        }
        
    }

    

}
import { Injectable }    from '@angular/core';

import { Employee, Message, User, Group, PensionPeriod, ContributionRate,
 SalaryRate, RegisterEmployer, Schedule, ScheduleWeekly, ScheduleMonthly, 
 NonSubmission, Event, RegisterInsured, Reconciliation, ReconciliationQuery } from './elements';

@Injectable()
export class EmployeesService {
    public employees:Employee[];

    constructor() {
    	this.employees = [];
    }

}

@Injectable()
export class UsersService {
    public users:User[];

    constructor() {
    	this.users = [];
    }

}

@Injectable()
export class InsuredUsersService {
    public users:User[];

    constructor() {
        this.users = [];
    }

}

@Injectable()
export class GroupsService {
    public groups:Group[];

    constructor() {
    	this.groups = [];
    }

}

@Injectable()
export class PensionPeriodService {
    public periods:PensionPeriod[];

    constructor() {
        this.periods = [];
    }

}

@Injectable()
export class ContributionRateService {
    public rates:ContributionRate[];

    constructor() {
        this.rates = [];
    }

}

@Injectable()
export class SalaryRateService {
    public rates:SalaryRate[];

    constructor() {
        this.rates = [];
    }

}

@Injectable()
export class RegisterEmployerService {
    public employer:RegisterEmployer[];

    constructor() {
        this.employer = [];
    }

}

@Injectable()
export class RegisterInsuredService {
    public insured:RegisterInsured[];

    constructor() {
        this.insured = [];
    }

}

@Injectable()
export class UploadService {
    public schedules:Schedule[];

    constructor() {
        this.schedules = [];
    }

}

@Injectable()
export class ScheduleService {
    public schedule:Schedule;
    public contributionRate:ContributionRate;
    public salaryRate:SalaryRate;

    public weeks:number;

    public monthlyEmployees:number = 0;
    public monthlyActualEarnings:number = 0;
    public monthlyInsurableEarnings:number = 0;
    public monthlyContributionsPayment:number = 0;
    public monthlyEmployerContributionsPayment:number = 0;
    public monthlyEmployeeContributionsPayment:number = 0;
    public weeklyEmployees:number = 0;
    public weeklyActualEarnings:number = 0;
    public weekly1ActualEarnings:number = 0;
    public weekly2ActualEarnings:number = 0;
    public weekly3ActualEarnings:number = 0;
    public weekly4ActualEarnings:number = 0;
    public weekly5ActualEarnings:number = 0;
    public weeklyInsurableEarnings:number = 0;
    public weeklyContributionsPayment:number = 0;
    public weeklyEmployerContributionsPayment:number = 0;
    public weeklyEmployeeContributionsPayment:number = 0;

    constructor( private _employees: EmployeesService,
        private _ContributionRateService: ContributionRateService,
        private _SalaryRateService: SalaryRateService,
        private _PensionPeriodService: PensionPeriodService ) {
        this.schedule = new Schedule();
        this.contributionRate = new ContributionRate();
        this.salaryRate = new SalaryRate();
        this.schedule.void = '0';
        this.schedule.downloaded = 0;
        this.schedule.scheduleNumber;
        this.schedule.schedulesMonthly = [];
        this.schedule.schedulesWeekly = [];
    }

    generate( year:number = this.schedule.year, month:number = this.schedule.month, existingSchedule:Schedule = null, fromExistingSchedule:boolean = false) {
        this.schedule.submissionDate = null;
        this.schedule.downloaded = 0;
        this.schedule.year = year;
        this.schedule.month = month;
        this.schedule.surcharge = 0;
        this.schedule.interest = 0;
        var period = new Date(year, month-1);
        var day, date;

        //get the number of weeks in the month
        this.weeks = 0;
        day = 1;
        date = new Date(year, month-1, day);
        while (date.getMonth() === month-1) {
            if (date.getDay() === 1) { // Sun=0, Mon=1, Tue=2, etc.
                this.weeks += 1;
            }
            day += 1;
            date = new Date(year, month-1, day);
        }

        //set rates and pension age based on period
        this.contributionRate = this._ContributionRateService.rates.filter(function( obj ) {
            return (new Date((obj['periodStart']+' 00:00').replace(/-/g, "/")) <= period && new Date((obj['periodEnd']+' 00:00').replace(/-/g, "/")) >= period);
        })[0];
        this.salaryRate = this._SalaryRateService.rates.filter(function( obj ) {
            return (new Date((obj['periodStart']+' 00:00').replace(/-/g, "/")) <= period && new Date((obj['periodEnd']+' 00:00').replace(/-/g, "/")) >= period);
        })[0];

        //Is schedule already exist generate from it
        if (existingSchedule) {

            if (!fromExistingSchedule) {
                this.schedule.id = existingSchedule.id;
            } else {
                delete this.schedule.id;
            }
            //sometimes schedulesMonthly is returned as an object
            if (existingSchedule.schedulesMonthly.constructor !== Array) {
                existingSchedule.schedulesMonthly = this.arrayFromObject(existingSchedule.schedulesMonthly);
            }
            if (existingSchedule.schedulesWeekly.constructor !== Array) {
                existingSchedule.schedulesWeekly = this.arrayFromObject(existingSchedule.schedulesWeekly);
            }
            this.schedule.schedulesMonthly =  existingSchedule.schedulesMonthly;
            this.schedule.schedulesWeekly =  existingSchedule.schedulesWeekly;
            if (existingSchedule.surcharge) {
                this.schedule.surcharge = existingSchedule.surcharge;
            }
            if (existingSchedule.interest) {
                this.schedule.interest = existingSchedule.interest;
            }

            //make all employee inactive
            for (var a=0; a < this._employees.employees.length; a++) {
                this._employees.employees[a].active = false;
            }

            for (var i=0; i < this.schedule.schedulesMonthly.length; i++) {
                delete this.schedule.schedulesMonthly[i]['scheduleId'];
                if (fromExistingSchedule) {
                    this.schedule.schedulesMonthly[i].weeksWorked = this.weeks;
                    delete this.schedule.schedulesMonthly[i]['id'];
                }
                //set employees to active that are in existing Schedule
                for (var a=0; a < this._employees.employees.length; a++) {
                    if (this._employees.employees[a].ssn == this.schedule.schedulesMonthly[i].ssn ) {
                        this._employees.employees[a].active = true;
                        //add back the types
                        this._employees.employees[a].type = this.categoryCheck(period, this._employees.employees[a].dateOfBirth, this._employees.employees[a].dateOfRetirement, this._employees.employees[a].type)
                        this.schedule.schedulesMonthly[i].type = this._employees.employees[a].type;
                    }
                }
            }
            if (this.weeks == 4) {
                for (var i=0; i < this.schedule.schedulesWeekly.length; i++) {
                    this.schedule.schedulesWeekly[i].weekFive = 0;
                }
            }
            for (var i=0; i < this.schedule.schedulesWeekly.length; i++) {
                delete this.schedule.schedulesWeekly[i]['scheduleId'];
                if (fromExistingSchedule) {
                    delete this.schedule.schedulesWeekly[i]['id'];
                }
                //set employees to active that are in existing Schedule
                for (var a=0; a < this._employees.employees.length; a++) {
                    if (this._employees.employees[a].ssn == this.schedule.schedulesWeekly[i].ssn ) {
                        this._employees.employees[a].active = true;
                        //add back the types
                        this._employees.employees[a].type = this.categoryCheck(period, this._employees.employees[a].dateOfBirth, this._employees.employees[a].dateOfRetirement, this._employees.employees[a].type)
                        this.schedule.schedulesWeekly[i].type = this._employees.employees[a].type;
                    }
                }
            }
        } else {
            this.schedule.id = null;
            this.schedule.schedulesMonthly = [];
            this.schedule.schedulesWeekly = [];
            for (var i=0; i < this._employees.employees.length; i++) {
                this._employees.employees[i].type = this.categoryCheck(period, this._employees.employees[i].dateOfBirth, this._employees.employees[i].dateOfRetirement, this._employees.employees[i].type)
                if (this._employees.employees[i]['payFrequency'] == 'M' && this._employees.employees[i]['active'] == true) {
                    var monthSched = new ScheduleMonthly();
                    monthSched.ssn = this._employees.employees[i].ssn;
                    monthSched.firstName = this._employees.employees[i].firstName;
                    monthSched.lastName = this._employees.employees[i].lastName;
                    monthSched.weeksWorked = this.weeks;
                    monthSched.actualEarnings = 0;
                    monthSched.insurableEarnings = 0;
                    monthSched.employeeContribution = 0;
                    monthSched.employerContribution = 0;
                    monthSched.type = this._employees.employees[i].type;
                    this.schedule.schedulesMonthly.push( monthSched );
                } else if (this._employees.employees[i]['payFrequency'] == 'W' && this._employees.employees[i]['active'] == true)  {
                    var weekSched = new ScheduleWeekly();
                    weekSched.ssn = this._employees.employees[i].ssn;
                    weekSched.firstName = this._employees.employees[i].firstName;
                    weekSched.lastName = this._employees.employees[i].lastName;
                    weekSched.weekTwo = 0;
                    weekSched.weekOne = 0;
                    weekSched.weekThree = 0;
                    weekSched.weekFour = 0;
                    weekSched.weekFive = 0;
                    weekSched.insurableEarnings = 0;
                    weekSched.employeeContribution = 0;
                    weekSched.employerContribution = 0;
                    weekSched.type = this._employees.employees[i].type;
                    this.schedule.schedulesWeekly.push( weekSched );
                }
            }
        }
        this.setSummary(this.schedule);
    }

    arrayFromObject(obj:any, val:string = '', direction:string = 'desc'):any[] {
        //var returnArray = Object.values(obj);
        var returnArray = Object.keys(obj).map(function(itm) { return obj[itm]; });
        if (direction == 'desc') {
            returnArray.sort( function(prop1, prop2) {
                if ( prop1[val] > prop2[val] ){
                    return -1;
                }else if( prop1[val] < prop2[val] ){
                    return 1;
                }else{
                    return 0;  
                }
            });
        } else {
            returnArray.sort( function(prop1, prop2) {
                if ( prop1[val] < prop2[val] ){
                    return -1;
                }else if( prop1[val] > prop2[val] ){
                    return 1;
                }else{
                    return 0;  
                }
            });
        }
        
        return returnArray;
    }

    addEmployee(employee:Employee) {
        if (employee.payFrequency == 'M'){
            var monthSched = new ScheduleMonthly();
            monthSched.ssn = employee.ssn;
            monthSched.firstName = employee.firstName;
            monthSched.lastName = employee.lastName;
            monthSched.weeksWorked = this.weeks;
            monthSched.actualEarnings = 0;
            monthSched.insurableEarnings = 0;
            monthSched.employeeContribution = 0;
            monthSched.employerContribution = 0;
            monthSched.type = employee.type;
            this.schedule.schedulesMonthly.push( monthSched );
        } else {
            var weekSched = new ScheduleWeekly();
            weekSched.ssn = employee.ssn;
            weekSched.firstName = employee.firstName;
            weekSched.lastName = employee.lastName;
            weekSched.weekTwo = 0;
            weekSched.weekOne = 0;
            weekSched.weekThree = 0;
            weekSched.weekFour = 0;
            weekSched.weekFive = 0;
            weekSched.insurableEarnings = 0;
            weekSched.employeeContribution = 0;
            weekSched.employerContribution = 0;
            weekSched.type = employee.type;
            this.schedule.schedulesWeekly.push( weekSched );
        }
        this.setSummary();
    }

    removeEmployee(ssn:number) {
        for (var i=0; i < this.schedule.schedulesMonthly.length; i++) {
            if (this.schedule.schedulesMonthly[i]['ssn'] == ssn) {
                this.schedule.schedulesMonthly.splice(i,1);
            }
        }
        for (var i=0; i < this.schedule.schedulesWeekly.length; i++) {
            if (this.schedule.schedulesWeekly[i]['ssn'] == ssn) {
                this.schedule.schedulesWeekly.splice(i,1);
            }
        }
        this.setSummary();
    }

    setSummary(schedule:any = this.schedule) {
        var mEmployees = 0;
        var mActualEarnings = 0;
        var mInsurableEarnings = 0;
        var mContributionsPayment = 0;
        var mEmployerContributionsPayment = 0;
        var mEmployeeContributionsPayment = 0;
        var wEmployees = 0;
        var wActualEarnings = 0;
        var w1ActualEarnings = 0;
        var w2ActualEarnings = 0;
        var w3ActualEarnings = 0;
        var w4ActualEarnings = 0;
        var w5ActualEarnings = 0;
        var wInsurableEarnings = 0;
        var wContributionsPayment = 0;
        var wEmployerContributionsPayment = 0;
        var wEmployeeContributionsPayment = 0;

        for (var i=0; i < schedule.schedulesMonthly.length; i++) {
            mEmployees ++;
            mActualEarnings += Number(Math.round(schedule.schedulesMonthly[i]['actualEarnings']* 1e2 ) / 1e2);
            mInsurableEarnings += Number(Math.round(schedule.schedulesMonthly[i]['insurableEarnings']* 1e2 ) / 1e2);
            mContributionsPayment += Number(Math.round(schedule.schedulesMonthly[i]['employerContribution']* 1e2 ) / 1e2) + Number(Math.round(schedule.schedulesMonthly[i]['employeeContribution']* 1e2 ) / 1e2);
            mEmployerContributionsPayment += Number(Math.round(schedule.schedulesMonthly[i]['employerContribution']* 1e2 ) / 1e2);
            mEmployeeContributionsPayment += Number(Math.round(schedule.schedulesMonthly[i]['employeeContribution']* 1e2 ) / 1e2);
        }
        for (var i=0; i < schedule.schedulesWeekly.length; i++) {
            wEmployees ++;
            wActualEarnings += Number(Math.round(schedule.schedulesWeekly[i]['weekOne']* 1e2 ) / 1e2) + Number(Math.round(schedule.schedulesWeekly[i]['weekTwo']* 1e2 ) / 1e2) + Number(Math.round(schedule.schedulesWeekly[i]['weekThree']* 1e2 ) / 1e2) +Number(Math.round(schedule.schedulesWeekly[i]['weekFour']* 1e2 ) / 1e2) +Number(Math.round(schedule.schedulesWeekly[i]['weekFive']* 1e2 ) / 1e2);
            w1ActualEarnings += Number(Math.round(schedule.schedulesWeekly[i]['weekOne']* 1e2 ) / 1e2);
            w2ActualEarnings += Number(Math.round(schedule.schedulesWeekly[i]['weekTwo']* 1e2 ) / 1e2) 
            w3ActualEarnings += Number(Math.round(schedule.schedulesWeekly[i]['weekThree']* 1e2 ) / 1e2) 
            w4ActualEarnings += Number(Math.round(schedule.schedulesWeekly[i]['weekFour']* 1e2 ) / 1e2) 
            w5ActualEarnings += Number(Math.round(schedule.schedulesWeekly[i]['weekFive']* 1e2 ) / 1e2) 
            wInsurableEarnings += Number(Math.round(schedule.schedulesWeekly[i]['insurableEarnings']* 1e2 ) / 1e2);
            wContributionsPayment += Number(Math.round(schedule.schedulesWeekly[i]['employerContribution']* 1e2 ) / 1e2) + Number(Math.round(schedule.schedulesWeekly[i]['employeeContribution']* 1e2 ) / 1e2);
            wEmployerContributionsPayment += Number(Math.round(schedule.schedulesWeekly[i]['employerContribution']* 1e2 ) / 1e2);
            wEmployeeContributionsPayment += Number(Math.round(schedule.schedulesWeekly[i]['employeeContribution']* 1e2 ) / 1e2);
        }
        this.monthlyEmployees = mEmployees;
        this.monthlyActualEarnings = mActualEarnings;
        this.monthlyInsurableEarnings = mInsurableEarnings;
        this.monthlyContributionsPayment = mContributionsPayment;
        this.monthlyEmployerContributionsPayment = mEmployerContributionsPayment;
        this.monthlyEmployeeContributionsPayment = mEmployeeContributionsPayment;
        this.weeklyEmployees = wEmployees;
        this.weeklyActualEarnings = wActualEarnings;
        this.weekly1ActualEarnings = w1ActualEarnings;
        this.weekly2ActualEarnings = w2ActualEarnings;
        this.weekly3ActualEarnings = w3ActualEarnings;
        this.weekly4ActualEarnings = w4ActualEarnings;
        this.weekly5ActualEarnings = w5ActualEarnings;
        this.weeklyInsurableEarnings = wInsurableEarnings;
        this.weeklyContributionsPayment = wContributionsPayment;
        this.weeklyEmployerContributionsPayment = wEmployerContributionsPayment;
        this.weeklyEmployeeContributionsPayment = wEmployeeContributionsPayment;

        var currentYear = (new Date()).getFullYear();
        var currentMonth = (new Date()).getMonth() + 1;

        var months;
        months = (currentYear - schedule.year) * 12;
        months -= schedule.month;
        months += currentMonth-1;

        if (months > 0) {
            this.schedule.surcharge = ((this.monthlyContributionsPayment + this.weeklyContributionsPayment) * 0.1);
            this.schedule.interest = ((this.monthlyContributionsPayment + this.weeklyContributionsPayment) * (months-1)/100);
        }

    }

    categoryCheck(period:Date, dateOfBirth:string, dateOfRetirement:string, oldCategory:string):string{
        if (!dateOfBirth) {
            return 'regular';
        }
        var birthday = new Date((dateOfBirth+' 00:00').replace(/-/g, "/"));
        var age = period.getFullYear() - birthday.getFullYear();
        var ageMonth = period.getMonth() - birthday.getMonth();
        if (ageMonth < 0 || (ageMonth === 0 && period.getDate() < birthday.getDate())) {
            age--;
        }
        //get the first monday in the month
        while (period.getDay() !== 1) {
            period.setDate(period.getDate() + 1);
        }

        if (dateOfRetirement) {
            var retirementday = new Date((dateOfRetirement+' 00:00').replace(/-/g, "/"));
            var retirementFirstOfMonth = new Date(retirementday.getFullYear(), retirementday.getMonth(), 1);
            if (retirementFirstOfMonth <= period) {
                return 'pension';
            }
        }
        var pensionable = this._PensionPeriodService.periods.filter(function( obj ) {
            var compBirthday = new Date((dateOfBirth+' 00:00').replace(/-/g, "/"));
            compBirthday.setFullYear( birthday.getFullYear() + obj['age'] );
            return (new Date((obj['periodStart']+' 00:00').replace(/-/g, "/")) <= compBirthday && new Date((obj['periodEnd']+' 00:00').replace(/-/g, "/")) >= compBirthday);
        })[0];
        if ((pensionable && (new Date(pensionable.age+birthday.getFullYear(), birthday.getMonth(), birthday.getDate()))<= period) || age < 16) {
            return 'pension';
        } else if (oldCategory == 'pension') {
            oldCategory = 'regular';
        } 
        return oldCategory;
    }
}

@Injectable()
export class ScheduleListService {
    public schedule:Schedule[];

    constructor() {
        this.schedule = [];
    }

}

@Injectable()
export class NonSubmissionListService {
    public schedule:NonSubmission[];

    constructor() {
        this.schedule = [];
    }
}

@Injectable()
export class ScheduleNotDownloadedService {
    public schedule:Schedule[];

    constructor() {
        this.schedule = [];
    }

}

@Injectable()
export class MessageService {
    public messages:Message[];

    constructor() {
        this.messages = [];
    }

}

@Injectable()
export class EventService {
    public event:Event[];

    constructor() {
        this.event = [];
    }

}

@Injectable()
export class ReconciliationService {
    public reconciliations:Reconciliation[];

    constructor() {
        this.reconciliations = [];
    }

}

@Injectable()
export class ReconciliationQueryService {
    public reconciliationQuerys:ReconciliationQuery[];

    constructor() {
        this.reconciliationQuerys = [];
    }

}
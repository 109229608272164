import { BaseComponent } from './base.component';

export abstract class BaseSelectComponent extends BaseComponent {
    rowSelected:string;

    focusFunction(row:string):void {
        if(row != undefined) {
            this.rowSelected = row;
        } else {
            this.resetForm();
        }
    }
}
import { Component, OnInit, ApplicationRef } from '@angular/core';

import { BaseEntryComponent } from '../base.entry.component';

import { HTTPService } from '../services/http.service';
import { ContributionRateService } from '../services/elements.service';
import { ContributionRate } from '../services/elements';

@Component({
    selector: 'admin-contribution',
    templateUrl: './admin-contribution.component.html',
})
export class AdminContributionComponent extends BaseEntryComponent  implements OnInit {
    //values in form
    rateFrom: string;
    rateTo: string;
    rateTotal: number;
    rateEmployer: number;
    rateEmployee: number;
    rateEmployerInj: number;
    rateEmployeeInj: number;
    rateEmployerGov: number;
    rateEmployeeGov: number;

    editObj:any;



    constructor(private _httpService: HTTPService, private applicationRef: ApplicationRef, private rates: ContributionRateService ) {
        super();
    }

    ngOnInit(): void {
        this.setComponentList(this.rates.rates);
        this.focusFunction(this.rates.rates[0]);
    }

    focusFunction(row:any):void {
        if(row != undefined) {
            this.rowSelected = row['id'];
            this.rateFrom = new Date((row['periodStart']).replace(/-/g, "/")).toISOString().substring(0, 10);
            this.rateTo = new Date((row['periodEnd']).replace(/-/g, "/")).toISOString().substring(0, 10);
            this.rateTotal = row['totalRate'];
            this.rateEmployer = row['regularEmployerRate'];
            this.rateEmployee = row['regularEmployeeRate'];
            this.rateEmployerInj = row['injuryEmployerRate'];
            this.rateEmployeeInj = row['injuryEmployeeRate'];
            this.rateEmployerGov = row['governmentPensionableEmployerRate'];
            this.rateEmployeeGov = row['governmentPensionableEmployeeRate'];
            this.resetForm();
        }
    }

    onSubmit() {
        this.editObj =
        {
            'periodStart': this.rateFrom,
            'periodEnd': this.rateTo,
            'totalRate': this.rateTotal,
            'regularEmployerRate': this.rateEmployer,
            'regularEmployeeRate': this.rateEmployee,
            'injuryEmployerRate': this.rateEmployerInj,
            'injuryEmployeeRate': this.rateEmployeeInj,
            'governmentPensionableEmployerRate': this.rateEmployerGov,
            'governmentPensionableEmployeeRate': this.rateEmployeeGov
        };
        if (this.rowSelected) {
            this.editObj.id = this.rowSelected;
        }
        this.disabled = true;
        this.message = "Saving.........";
        this._httpService.reforgePromise('rate/contribution', this.editObj)
        .then(
                (data) => {this.returnData = data ; this.onResponceSave();}
            );
    }

    onResponceSave() {
        this.newEntry = false;
        this.disabled = false;
        if (this.returnData.message == "reforge successful") {
            //this.getEmployees();
            if (this.editObj.id) {
                for (var i=0; i < this.rates.rates.length; i++) {
                    if (this.rates.rates[i]['id'] == this.editObj.id) {
                        this.rates.rates[i].periodStart = this.editObj.periodStart;
                        this.rates.rates[i].periodEnd = this.editObj.periodEnd;
                        this.rates.rates[i].totalRate = this.editObj.totalRate;
                        this.rates.rates[i].regularEmployerRate = this.editObj.regularEmployerRate;
                        this.rates.rates[i].regularEmployeeRate = this.editObj.regularEmployeeRate;
                        this.rates.rates[i].injuryEmployerRate = this.editObj.injuryEmployerRate;
                        this.rates.rates[i].injuryEmployeeRate = this.editObj.injuryEmployeeRate;
                        this.rates.rates[i].governmentPensionableEmployeeRate = this.editObj.governmentPensionableEmployeeRate;
                        this.rates.rates[i].governmentPensionableEmployerRate = this.editObj.governmentPensionableEmployerRate;
                    }
                }
            } else {
                this.editObj.id = this.returnData.id;
                this.rates.rates.push(this.editObj);
            }
            this.componentList = this.rates.rates;
            document.getElementById("message").classList.remove('text-danger');
            document.getElementById("message").classList.add('text-success');
            this.message = "Save successful";
            setTimeout(() => {
                document.getElementById("message").classList.remove('text-success');
                document.getElementById("message").classList.add('text-danger');
                this.message = null
            }, 3000);
        } else {
            this.message = this.returnData.message;
        }
    }

    newRate() {
        this.newEntry = true;
        this.rowSelected = null;
        this.rateFrom = null;
        this.rateTo = null;
        this.rateTotal = null;
        this.rateEmployer = null;
        this.rateEmployee = null;
        this.rateEmployerInj = null;
        this.rateEmployeeInj = null;
        this.rateEmployerGov = null;
        this.rateEmployeeGov = null;
        this.resetForm();
        setTimeout(() => document.getElementById('rteFrom').focus(), 0.1);
    }



    searchEntry(ev:Event):void {
        //console.log((<HTMLTextAreaElement>ev.target).value);
        this.searchValue = (<HTMLTextAreaElement>ev.target).value.toLowerCase();
        this.componentList = this.rates.rates.filter(function( obj ) {
            return (obj['periodStart'].toString().toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1 || obj['periodEnd'].toString().toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1);
        });
    }

}
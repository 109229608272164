import { Component, OnInit, ApplicationRef, ViewChild } from '@angular/core';

import { BaseEntryComponent } from '../base.entry.component';

import { ScheduleEntryComponent } from '../components/schedule-entry.component';
import { ScheduleService } from '../services/elements.service';
import { Schedule } from '../services/elements';
import { HTTPService } from '../services/http.service';
import { EmployeesService, UploadService } from '../services/elements.service';

@Component({
    selector: 'user-schedule-upload',
    templateUrl: './user-schedule-upload.component.html',
})
export class UserScheduleUploadComponent extends BaseEntryComponent implements OnInit {
    private employeeList:boolean = false;
    @ViewChild('scheduleEntry') scheduleEntry: ScheduleEntryComponent;

    public scheduleIndex:any;
    selectSchedule:any;


    constructor(private _httpService: HTTPService, private applicationRef: ApplicationRef, public _ScheduleService:ScheduleService, private _UploadService:UploadService, private _employees: EmployeesService ) {
        super();
    }

    ngOnInit(): void {
        this.setComponentList(this._employees.employees);
        this.scheduleIndex = {
            years : {}
        };
        this.getScheduleIndex();

    }

    getScheduleIndex() {
        for(var i = 0;i<this._UploadService.schedules.length;i++) {
            var idLoop = this._UploadService.schedules[i]['id'];
            var yearOfLoop = this._UploadService.schedules[i]['year'];
            var monthOfLoop = this._UploadService.schedules[i]['month'];
            var scheduleNumberOfLoop = i + 1;
            var scheduleMethod = (this._UploadService.schedules[i]['uploadMethod'] == 'EW')? "upload": "schedule";
            if (typeof this.scheduleIndex.years[yearOfLoop] == 'undefined') {
                this.scheduleIndex.years[yearOfLoop] = {
                    year : yearOfLoop,
                    periods : {}
                };
            }
            if (typeof this.scheduleIndex.years[yearOfLoop].periods[monthOfLoop] == 'undefined') {
                this.scheduleIndex.years[yearOfLoop].periods[monthOfLoop]={
                    month : monthOfLoop,
                    schedules : {}
                };
            }
            if (typeof this.scheduleIndex.years[yearOfLoop].periods[monthOfLoop].schedules[scheduleNumberOfLoop] == 'undefined') {
                this.scheduleIndex.years[yearOfLoop].periods[monthOfLoop].schedules[scheduleNumberOfLoop]={
                    id : idLoop,
                    number : scheduleNumberOfLoop,
                    type: scheduleMethod
                };
            }
        }
    }

    setSchedule(schedule:any = -1):any {
        for (var i=0; i < this._UploadService.schedules.length; i++) {
            if (this._UploadService.schedules[i]['id'] == schedule.id) {
                this._ScheduleService.generate(this._UploadService.schedules[i].year, this._UploadService.schedules[i].month, this._UploadService.schedules[i], false);
            }
        }
    }

    toggleEmployeeList(): void {
        this.employeeList = !this.employeeList;
    }

    changeStatus(event:Event, id: number) {
        //update backend change employee service and add or remove from schedule
        for (var i=0; i < this._employees.employees.length; i++) {
            if (this._employees.employees[i]['id'] == id) {
                this._employees.employees[i].active = (<HTMLInputElement>event.target).checked
                if ((<HTMLInputElement>event.target).checked) {
                    //add to schedule
                    this._ScheduleService.addEmployee(this._employees.employees[i]);
                    this.scheduleEntry.autoSave();
                } else {
                    //remove from schedule
                    this._ScheduleService.removeEmployee(this._employees.employees[i].ssn);
                    this.scheduleEntry.autoSave();
                }
            }
        }
        var obj = {
            id: id,
            active : (<HTMLInputElement>event.target).checked
        }
        this._httpService.reforgePromise('employee', obj)
        .then(
                (data) => {this.returnData = data ; this.onResponceSave();}
            );
    }

    searchEntry(ev:Event):void {
        //console.log((<HTMLTextAreaElement>ev.target).value);
        this.searchValue = (<HTMLTextAreaElement>ev.target).value.toLowerCase();
        this.componentList = this._employees.employees.filter(function( obj ) {
            return (obj['firstName'].toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1 || obj['lastName'].toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1 || obj['ssn'].toString().toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1);
        });
    }

    onResponceSave() {
        this.disabled = false;
        if (this.returnData.message == "reforge successful") {

        } else {
            //alert("Error changing employee stataus. Please contact Administrator");
        }
    }
}
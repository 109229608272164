import { Component, OnInit, ApplicationRef } from '@angular/core';

import { BaseEntryComponent } from '../base.entry.component';

import { HTTPService } from '../services/http.service';
import { RegisterEmployerService } from '../services/elements.service';
import { MessagingService } from '../services/messaging.service';


@Component({
    selector: 'admin-registration',
    templateUrl: './admin-registration.component.html',
})
export class AdminRegistrationComponent extends BaseEntryComponent  implements OnInit {
    public selectedComplianceCheck:any;

    loadedEmployers:boolean = false;

    constructor(private httpService: HTTPService, 
        private applicationRef: ApplicationRef, 
        private _RegisterEmployerService: RegisterEmployerService,
        private _messagingService: MessagingService 
        ) {
        super();
    }

    ngOnInit(): void {
        this.loadedEmployers = false;
        var obja = {
                "search": [
                    {
                        "column":"status",
                        "operator":"!=",
                        "value":"dl"
                    }
                ]
            }
        this.httpService.retrievePromise('employer', obja)
        .then(
                (data) => {this.returnData = data ; this.onRegisterEmployerResponce();}
            )
        .catch(
                (error) => this.handleError(error)
            );
    }

    onRegisterEmployerResponce() {
        this.loadedEmployers = false;
        if (this.returnData.message == "retrieval successful") {
            this._RegisterEmployerService.employer = this.returnData.data;
            this.focusFunction(this._RegisterEmployerService.employer[0]);
            this.setComponentList(this._RegisterEmployerService.employer);
            this.loadedEmployers = true;
        } else {
        }
    }

    focusFunction(row:any):void {
        if(row != undefined) {
            this.rowSelected = row['id'];
            this.selectedComplianceCheck = row;
        }
    }

    allowDrop(ev:DragEvent) {
        ev.preventDefault();
    }

    drop(ev:DragEvent, action:string) {
        ev.preventDefault();
        var result = this.componentList.filter(function( obj ) {
          return obj.id == Number(ev.dataTransfer.getData("text"));
        });
        switch (action) {
            case "pe":
                //result[0]['status'] = 'pe';
                break;
            case "pr":
                if (result[0]['status'] == 'pe' || result[0]['status'] == 'nc'){
                    result[0]['status'] = 'pr';
                    this.updateEmployer(result[0]);
                }
                break;
            case "nc":
                if (result[0]['status'] == 'pr'){
                    result[0]['status'] = 'nc';
                    this.updateEmployer(result[0]);
                }
                break;
            case "co":
                if (result[0]['status'] == 'pr'){
                    result[0]['status'] = 'co';
                    this.updateEmployer(result[0]);
                }
                break;
        }
        this.originalComponentList = Object.assign([], this.componentList);
    }

    changStatus(employer:any, status:string) {
        employer['status'] = status;
        this.updateEmployer(employer);
    }

    completeRegistration(employer:any) {
        this._messagingService.createMessageAlert("Please wait while we complete Employer Registration.", false);
        this.httpService.genericPromise('employer/reforge/completed', employer)
            .then(
                    (data) => {this.returnData = data ; this.onResponceComplete(employer);}
                );
    }

    onResponceComplete(employer:any) {
        if (this.returnData.message == "reforge successful") {
            employer['status'] = 'co';
            this._messagingService.createMessageAlert("Registration Completed.", true);
        } else {
            this._messagingService.createMessageAlert("Error changing employer stataus. Please contact Administrator :", true);
        }
    }

    onResponceSave() {
        if (this.returnData.message == "reforge successful") {

        } else {
            //alert("Error changing employer stataus. Please contact Administrator :" +this.returnData.message);
        }
    }

    updateEmployer(employer:any) {
        this.httpService.genericPromise('employer/reforge/any', employer)
            .then(
                    (data) => {this.returnData = data ; this.onResponceSave();}
                );
    }

    startDrag(ev:DragEvent) {
        //ev.preventDefault();
        ev.dataTransfer.setData("text", (<HTMLTextAreaElement>ev.target).id)
    }

    searchEntry(ev:Event):void {
        //console.log((<HTMLTextAreaElement>ev.target).value);
        // this.componentList = this.groups.groups.filter(function( obj ) {
        //     return (obj['name'].toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1);
        // });
    }

}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HTTPService } from './services/http.service';
import { UserService } from './services/user.service';
import { MenuService } from './services/menu.service';
import { ReturnData } from './services/return.data';
import { MessagingService } from './services/messaging.service';

import { EmployeesService } from './services/elements.service';
import { ScheduleListService } from './services/elements.service';

import { JwtHelperService } from '@auth0/angular-jwt';

declare var jQuery:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
	jwtHelper: JwtHelperService = new JwtHelperService();
	visable:boolean = false;
	burgerActive:boolean = false;
    returnData: ReturnData;
    dataLoaded:boolean = false;
    currentCopyRightYear:String;

    displayMessageBody:string;
    displayMessage:string;
    messageWithClose:boolean= true;

	constructor (
    private router: Router,
    private _httpService: HTTPService,
    public user:UserService,
    private menu:MenuService,
    private employees:EmployeesService,
    private _ScheduleListService:ScheduleListService,
    private _messagingService: MessagingService
    ) {
        this._messagingService.messageAlert$.subscribe(astronaut => this.createMessage(astronaut));
        this._messagingService.hideAlert$.subscribe(astronaut => this.hideMessage());
    }

    ngOnInit(): void {
        if (localStorage.getItem("id_token") !== null && !this.jwtHelper.isTokenExpired(localStorage.getItem("id_token"))) {
            sessionStorage.setItem("id_token", localStorage.getItem("id_token"));
			this.user.user = this.jwtHelper.decodeToken(localStorage.getItem("id_token"));
			this.menu.setMenu(this.user.user.typ);
            this.user.loadData();
        } else {
            this.user.user = null;
            this.dataLoaded = true;
            localStorage.removeItem('id_token');
            sessionStorage.clear();
        	//this.router.navigate(['/login']);
        }
        var dt = new Date();
        this.currentCopyRightYear = dt.getFullYear().toString();
    }

    logoutRequest():void {
    	localStorage.removeItem('id_token');
    	sessionStorage.clear();
    	this.user.user = null;
    	this.menu.close();
    	this.router.navigate(['/login']);
    	if (this.burgerActive) {
    		this.toggleNav();
    	}
    }

	toggleNav():void {
		this.burgerActive = !this.burgerActive;
		if (this.burgerActive) {
			document.getElementById("mySidenav").style.width = "250px";
		    //document.getElementById("overlay").style.display = "block";
		    document.getElementById("main").style.marginLeft = "250px";
		} else {
			document.getElementById("mySidenav").style.width = "0";
		    //document.getElementById("overlay").style.display = "none";
		    document.getElementById("main").style.marginLeft = "0";
		}
	}

    checkAccess(role:string):boolean {
        for (var i=0; i < this.user.user.prm.length; i++) {
            if (this.user.user.prm[i] == role) {
                return true;
            }
        }
        return false;
    }

    protected createMessage(message:any) {
        this.displayMessage = message.message;
        this.displayMessageBody = message.body;
        this.messageWithClose = message.canClose;
        if (message.canClose) {
            jQuery(document.getElementById('messageModal')).modal('show');
        } else {
            jQuery(document.getElementById('messageModal')).modal({backdrop: 'static', keyboard: false});
        }
    }

    protected hideMessage() {
        jQuery(document.getElementById('messageModal')).modal('hide');
    }

    showAbout() {
        let message = "Copyright © "+this.currentCopyRightYear+" National Insurance Services of St Vincent and the Grenadines";
        this.createMessage({'message': 'eSubmit Version 3.1', 'canClose': true, 'body': message});
    }
}

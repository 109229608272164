import { Component, OnInit, ApplicationRef } from '@angular/core';

import { BaseEntryComponent } from '../base.entry.component';

import { HTTPService } from '../services/http.service';
import { Schedule } from '../services/elements';
import { NonSubmissionListService } from '../services/elements.service';

@Component({
    selector: 'admin-non-submission',
    templateUrl: './admin-non-submission.component.html',
})
export class AdminNonSubmissionComponent extends BaseEntryComponent implements OnInit {
    order:string = 'ascending';
    selected:string = null;
    returnFile: any;
    schedules:any;



    constructor(private _httpService: HTTPService, private applicationRef: ApplicationRef, private _NonSubmissionListService:NonSubmissionListService ) {
        super();
    }

    ngOnInit(): void {
        this._httpService.retrievePromise('nonsubmission')
        .then(
                (data) => {this.returnData = data ; this.onNonSubmissionResponce();}
            )
    }

    onNonSubmissionResponce() {
        if (this.returnData.message == "retrieval successful") {
            this._NonSubmissionListService.schedule = this.returnData.data;
            this.setComponentList(this.returnData.data);
        } else {

        }
    }


    focusFunction(row:any):void {
        if(row != undefined) {
            this.rowSelected = row['id'];
            this.schedules = row;
        }
    }

    searchEntry(ev:Event):void {
        //console.log((<HTMLTextAreaElement>ev.target).value);
        this.searchValue = (<HTMLTextAreaElement>ev.target).value.toLowerCase();
        this.componentList = this._NonSubmissionListService.schedule.filter(function( obj ) {
            return (obj['id'].toString().toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1);
        });
    }

}
import { Component, OnInit, ApplicationRef, ViewChild } from '@angular/core';

import { BaseEntryComponent } from '../base.entry.component';

import { ScheduleEntryComponent } from '../components/schedule-entry.component';
import { ScheduleService } from '../services/elements.service';
import { Schedule } from '../services/elements';
import { HTTPService } from '../services/http.service';
import { EmployeesService } from '../services/elements.service';

@Component({
    selector: 'user-schedule',
    templateUrl: './user-schedule.component.html',
})
export class UserScheduleComponent extends BaseEntryComponent implements OnInit {
    public employeeList:boolean = false;
    @ViewChild('scheduleEntry') scheduleEntry: ScheduleEntryComponent;


    constructor(private _httpService: HTTPService, private applicationRef: ApplicationRef, public _ScheduleService:ScheduleService, private _employees: EmployeesService ) {
        super();
        this.componentList = this._employees.employees
    }

    ngOnInit(): void {
    }

    toggleEmployeeList(): void {
        this.employeeList = !this.employeeList;
    }

    changeStatus(event:Event, id: number) {
        //update backend change employee service and add or remove from schedule
        for (var i=0; i < this._employees.employees.length; i++) {
            if (this._employees.employees[i]['id'] == id) {
                this._employees.employees[i].active = (<HTMLInputElement>event.target).checked
                if ((<HTMLInputElement>event.target).checked) {
                    //add to schedule
                    this._ScheduleService.addEmployee(this._employees.employees[i]);
                    this.scheduleEntry.autoSave();
                } else {
                    //remove from schedule
                    this._ScheduleService.removeEmployee(this._employees.employees[i].ssn);
                    this.scheduleEntry.autoSave();
                }
            }
        }
        var obj = {
            id: id,
            active : (<HTMLInputElement>event.target).checked
        }
        this._httpService.reforgePromise('employee', obj)
        .then(
                (data) => {this.returnData = data ; this.onResponceSave();}
            );
    }

    searchEntry(ev:Event):void {
        //console.log((<HTMLTextAreaElement>ev.target).value);
        this.componentList = this._employees.employees.filter(function( obj ) {
            return (obj['firstName'].toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1 || obj['lastName'].toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1 || obj['ssn'].toString().toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1);
        });
    }

    onResponceSave() {
        this.disabled = false;
        if (this.returnData.message == "reforge successful") {

        } else {
            //alert("Error changing employee stataus. Please contact Administrator");
        }
    }
}
import { Injectable, Output, EventEmitter } from '@angular/core';
import { Subject }    from 'rxjs';

@Injectable()
export class MessagingService {
	private messageAlert = new Subject<object>();
	messageAlert$ = this.messageAlert.asObservable();

	private hideAlert = new Subject<object>();
	hideAlert$ = this.hideAlert.asObservable();

  constructor() { }

  createMessageAlert(message:string, canClose:boolean = true, body:string = null)
  {
  	this.messageAlert.next({'message': message, 'canClose': canClose, 'body': body});
  }

  hideMessageAlert()
  {
  	this.hideAlert.next();
  }
}

import { Component, OnInit, ApplicationRef } from '@angular/core';

import { BaseEntryComponent } from '../base.entry.component';

import { HTTPService } from '../services/http.service';
import { EventService } from '../services/elements.service';

@Component({
    selector: 'admin-event',
    templateUrl: './admin-event.component.html',
})
export class AdminEventComponent extends BaseEntryComponent implements OnInit {
    //values in form
    eventTitle: string;
    eventDetails: string;
    eventMinute: string;
    eventHour: string;
    eventDayWeek: string;
    eventDayMonth: string;
    eventWeekMonth: string;
    eventWeekYear: string;
    eventMonth: string;
    eventYear: string;
    eventDuration: number;

    editObj:any;

    constructor(private _httpService: HTTPService, private applicationRef: ApplicationRef, private events: EventService ) {
        super();
    }

    ngOnInit(): void {
        this.focusFunction(this.events.event[0]);
        this.setComponentList(this.events.event);
    }

    focusFunction(row:any):void {
        if(row != undefined) {
            this.rowSelected = row['id'];
            
            this.eventTitle = row['title'];
            this.eventDetails = row['details'];
            this.eventMinute = row['minute'];
            this.eventHour = row['hour'];
            this.eventDayWeek = row['dayWeek'];
            this.eventDayMonth = row['dayMonth'];
            this.eventWeekMonth = row['weekMonth'];
            this.eventWeekYear = row['weekYear'];
            this.eventMonth = row['month'];
            this.eventYear = row['year'];
            this.eventDuration = row['duration'];

            this.resetForm();
        }
    }

    onAdd():void {

        this.rowSelected = null;
            
        this.eventTitle = null;
        this.eventDetails = null;
        this.eventMinute = null;
        this.eventHour = null;
        this.eventDayWeek = null;
        this.eventDayMonth = null;
        this.eventWeekMonth = null;
        this.eventWeekYear = null;
        this.eventMonth = null;
        this.eventYear = null;
        this.eventDuration = null;

        this.message = "Click save when entry is complete.";

        this.resetForm();

    }

    getValue(variable:string):any {
        //Cast TypeScript HTMLElement as JavaScript HTMLInputElement and return value
        return (<HTMLInputElement>document.getElementById(variable)).value != '' ? (<HTMLInputElement>document.getElementById(variable)).value : null;
    }

    onSubmit() {

        var selection = false;

        if (this.rowSelected) {
            selection = true;
        } else {

            this.eventTitle = this.getValue('eTitle');
            this.eventDetails = this.getValue('eDetails');
            this.eventMinute = this.getValue('eMinute');
            this.eventHour = this.getValue('eHour');
            this.eventDayWeek = this.getValue('eDayWeek');
            this.eventDayMonth = this.getValue('eDayMonth');
            this.eventWeekMonth = this.getValue('eWeekMonth');
            this.eventWeekYear = this.getValue('eWeekYear');
            this.eventMonth = this.getValue('eMonth');
            this.eventYear = this.getValue('eYear');
            this.eventDuration = this.getValue('eDuration');
        }
        
        this.editObj =
        {
            'title': this.eventTitle,
            'details': this.eventDetails,
            'minute': this.eventMinute,
            'hour': this.eventHour,
            'dayWeek': this.eventDayWeek,
            'dayMonth': this.eventDayMonth,
            'weekMonth': this.eventWeekMonth,
            'weekYear': this.eventWeekYear,
            'month': this.eventMonth,
            'year': this.eventYear,
            'duration': this.eventDuration
        };

        if (selection = true) this.editObj.id = this.rowSelected;

        this.disabled = true;
        this.message = "Saving...";
        this._httpService.reforgePromise('event', this.editObj)
        .then(
                (data) => {this.returnData = data ; this.onResponceSave();}
            );
    }

    onResponceSave() {
        this.disabled = false;
        if (this.returnData.message == "reforge successful") {
            //this.getEmployees();
            if (this.editObj.id) {
                for (var i=0; i < this.events.event.length; i++) {
                    if (this.events.event[i]['id'] == this.editObj.id) {
                        this.events.event[i].title = this.editObj.title;
                        this.events.event[i].details = this.editObj.details;
                        this.events.event[i].minute = this.editObj.minute;
                        this.events.event[i].hour = this.editObj.hour;
                        this.events.event[i].dayWeek = this.editObj.dayWeek;
                        this.events.event[i].dayMonth = this.editObj.dayMonth;
                        this.events.event[i].weekMonth = this.editObj.weekMonth;
                        this.events.event[i].weekYear = this.editObj.weekYear;
                        this.events.event[i].month = this.editObj.month;
                        this.events.event[i].year = this.editObj.year;
                        this.events.event[i].duration = this.editObj.duration;
                    }
                }
            } else {
                this.editObj.id = this.returnData.id;
                this.events.event.push(this.editObj);
            }
            
            this.componentList = this.events.event;
            document.getElementById("message").classList.remove('text-danger');
            document.getElementById("message").classList.add('text-success');
            this.message = "Save successful";
            setTimeout(() => {
                document.getElementById("message").classList.remove('text-success');
                document.getElementById("message").classList.add('text-danger');
                this.message = null
            }, 3000);

        } else {
            this.message = this.returnData.message;
        }
    }

    onCancel() {
        this.resetForm();
        if (this.rowSelected) {
            for (var i=0; i < this.events.event.length; i++) {
                if (this.events.event[i]['id'].toString() == this.rowSelected) {
                    this.focusFunction(this.events.event[i]);
                }
            }
        } else {
            this.focusFunction(this.componentList[0]);
        }
    }

    searchEntry(ev:Event):void {
        //console.log((<HTMLTextAreaElement>ev.target).value);
        this.searchValue = (<HTMLTextAreaElement>ev.target).value.toLowerCase();
        this.componentList = this.events.event.filter(function( obj ) {
            return (obj['details'].toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1 || obj['title'].toString().toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1);
        });
    }
}
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { HttpModule }    from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ChartsModule } from 'ng2-charts';
import { NgxCaptchaModule } from 'ngx-captcha';

import { UserService } from './services/user.service';
import { MenuService } from './services/menu.service';
import { HTTPService } from './services/http.service';
import { MessagingService } from './services/messaging.service';

import { EmployeesService, UsersService, InsuredUsersService, GroupsService, PensionPeriodService, 
		ContributionRateService, SalaryRateService, RegisterEmployerService,
		ScheduleService, ScheduleListService, NonSubmissionListService, UploadService, 
        ScheduleNotDownloadedService, MessageService, RegisterInsuredService, EventService, ReconciliationService, ReconciliationQueryService } from './services/elements.service';


import { AuthGuard, AuthGuardAdmin, AuthGuardEmployer, AuthGuardLoggedOut, AuthGuardForceLoggedOut, AuthGuardSchedule } from './services/guards.service';

import { RegistrationComponent } from './registration.component';
import { RegistrationPostComponent } from './registration-post.component';
import { ForgotpasswordComponent } from './forgotpassword.component';
import { ForgotpasswordResetComponent } from './forgotpassword-reset.component';
import { ChangePasswordComponent }     from './change-password.component';
import { LoginComponent } from './login.component';
import { DashboardComponent } from './dashboard.component';
import { UserScheduleComponent } from './user-management/user-schedule.component';
import { UserScheduleUploadComponent } from './user-management/user-schedule-upload.component';
import { UserScheduleViewComponent } from './user-management/user-schedule-view.component';
import { UserScheduleMultiComponent } from './user-management/user-schedule-multi.component';
import { UserEmployeeComponent } from './user-management/user-employee.component';
import { HelpComponent }     from './help.component';
import { PageNotFoundComponent } from './page.not.found.component';


/****Admin Components****/
import { AdminContributionComponent } from './admin-management/admin-contribution.component';
import { AdminUserComponent } from './admin-management/admin-user.component';
import { AdminGroupComponent } from './admin-management/admin-group.component';
import { AdminEmployerComponent } from './admin-management/admin-employer.component';
import { AdminRegistrationComponent } from './admin-management/admin-registration.component';
import { AdminSalaryComponent } from './admin-management/admin-salary.component';
import { AdminScheduleComponent } from './admin-management/admin-schedule.component';
import { AdminPensionComponent } from './admin-management/admin-pension.component';
import { AdminDownloadComponent } from './admin-management/admin-download.component';
import { AdminMessageComponent } from './admin-management/admin-message.component';
import { AdminInsuredRegistrationComponent } from './admin-management/admin-insuredregistration.component';
import { AdminEventComponent } from './admin-management/admin-event.component';
import { AdminReconciliationComponent } from './admin-management/admin-reconciliation.component';
import { AdminNonSubmissionComponent } from './admin-management/admin-non-submission.component';
import { AdminVoidSubmissionComponent } from './admin-management/admin-void-submission.component';
import { SelfEmployedRegistrationComponent } from './admin-management/self-employed/self-employed-registration/self-employed-registration.component';


/****Special Components****/
import { ScheduleEntryComponent } from './components/schedule-entry.component';
import { ScheduleViewComponent } from './components/schedule-view.component';
import { ScheduleListComponent } from './components/schedule-list.component';
import { ScheduleUploadComponent } from './components/schedule-upload.component';

import { PaymentComponent } from './payment/payment.component';

/****Directives****/
import { CustomMinDirective } from './components/custom-min-validator.directive';
import { CustomMaxDirective } from './components/custom-max-validator.directive';
import { UiDatePickerDirective } from './components/ui-date-picker.directive';

@NgModule({
    declarations: [
    AppComponent,
    RegistrationComponent,
    RegistrationPostComponent,
    ForgotpasswordComponent,
    ForgotpasswordResetComponent,
    ChangePasswordComponent,
    DashboardComponent,
    LoginComponent,
    PageNotFoundComponent,
    UserScheduleComponent,
    UserScheduleUploadComponent,
    UserScheduleViewComponent,
    UserScheduleMultiComponent,
    UserEmployeeComponent,
    AdminContributionComponent,
    AdminUserComponent,
    AdminGroupComponent,
    AdminEmployerComponent,
    AdminRegistrationComponent,
    AdminSalaryComponent,
    AdminScheduleComponent,
    AdminPensionComponent,
    AdminDownloadComponent,
    AdminMessageComponent,
    AdminInsuredRegistrationComponent,
    AdminEventComponent,
    AdminReconciliationComponent,
    AdminNonSubmissionComponent,
    AdminVoidSubmissionComponent,
    ScheduleEntryComponent,
    ScheduleViewComponent,
    ScheduleListComponent,
    ScheduleUploadComponent,
    CustomMinDirective,
    CustomMaxDirective,
    UiDatePickerDirective,
    HelpComponent,
    SelfEmployedRegistrationComponent,
    PaymentComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,FormsModule,
	    HttpModule,
        HttpClientModule,
	    ChartsModule,
	    NgxCaptchaModule.forRoot({
	      reCaptcha2SiteKey: '6Lerki0UAAAAAA-6zBBw4dOqPWFsYj2fvMgKu7zH'
	    })
    ],
    providers: [ UserService, MenuService, HTTPService, EmployeesService, UsersService, MessagingService, InsuredUsersService, GroupsService, PensionPeriodService, ContributionRateService, SalaryRateService, RegisterEmployerService, ScheduleService, ScheduleListService, NonSubmissionListService, UploadService, ScheduleNotDownloadedService, MessageService, RegisterInsuredService, EventService, ReconciliationService, ReconciliationQueryService, AuthGuard, AuthGuardAdmin, AuthGuardLoggedOut, AuthGuardForceLoggedOut, AuthGuardEmployer, AuthGuardSchedule ],
    bootstrap: [AppComponent]
})
export class AppModule { }

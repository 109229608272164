import { Component, OnInit, ApplicationRef } from '@angular/core';

import { BaseEntryComponent } from '../base.entry.component';

import { HTTPService } from '../services/http.service';
import { UsersService, GroupsService } from '../services/elements.service';
import { User } from '../services/elements';

@Component({
    selector: 'admin-user',
    templateUrl: './admin-user.component.html',
})
export class AdminUserComponent extends BaseEntryComponent implements OnInit {
    //values in form
    usrName: string;
    usrUserName: string;
    usrEmail: string;
    usrGroup: number;
    eusrStatus: boolean;

    userObj:any;
    loadedUser:boolean = false;

    constructor(private _httpService: HTTPService, private applicationRef: ApplicationRef, private users: UsersService, private groups: GroupsService ) {
        super();
    }

    ngOnInit(): void {
        this.loadedUser = false;
        var obj =
        {
            "type":"A"
        };
        this._httpService.retrievePromise('user', obj)
        .then(
                (data) => {this.returnData = data ; this.onUserResponce();}
            )
        .catch(
                (error) => this.handleError(error)
            );
    }

    onGroupResponce() {
        this.loadedUser = false;
        if (this.returnData.message == "retrieval successful") {
            this.groups.groups = this.returnData.data;
            this.loadedUser = true;
        } else {
        }
    }

    focusFunction(row:any):void {
        if(row != undefined) {
            this.rowSelected = row['id'];
            this.usrName = row['name'];
            this.usrUserName = row['username'];
            this.usrEmail = row['emailAddress'];
            this.usrGroup = row['groups'][0]?row['groups'][0]['id']:null;
            this.eusrStatus= ((row['status'] == 'A') ? true : false);
            this.resetForm();
        }
    }

    onSubmit() {
        //set the selected after saving a new employee
        this.userObj =
        {
            'username': this.usrUserName,
            'name': this.usrName,
            'status': (this.eusrStatus?'A':'D'),
            'type': 'A',
            'emailAddress': this.usrEmail,
            'groups': [{'id':this.usrGroup}],
        };
        if (this.rowSelected) {
            this.userObj.id = this.rowSelected;
        } else {
            this.userObj.password = 'Password1';
        }
        this.disabled = true;
        this.message = "Saving.........";
        this._httpService.reforgePromise('user', this.userObj)
        .then(
                (data) => {this.returnData = data ; this.onResponceSave();}
            );
    }

    onResponceSave() {
        this.newEntry = false;
        this.disabled = false;
        if (this.returnData.message == "reforge successful") {
            //this.getEmployees();
            if (this.userObj.id) {
                for (var i=0; i < this.users.users.length; i++) {
                    if (this.users.users[i]['id'] == this.userObj.id) {
                        this.users.users[i].username = this.userObj.username;
                        this.users.users[i].name = this.userObj.name;
                        this.users.users[i].status = this.userObj.status;
                        this.users.users[i].type = this.userObj.type;
                        this.users.users[i].emailAddress = this.userObj.emailAddress;
                        this.users.users[i].groups = this.userObj.groups;
                    }
                }
            } else {
                this.userObj.id = this.returnData.id;
                this.users.users.push(this.userObj);
            }

            //save group
            var groupObj =
            {
                "user": this.returnData.id,
                "group": this.usrGroup
            };
            this._httpService.genericPromise('user/group/set', groupObj)
            .then(
                    (data) => {this.returnData = data ; this.onResponceGroupSave();}
                );
        } else {
            this.message = this.returnData.message;
        }
    }

    onResponceGroupSave() {
        if (this.returnData.message == "reforge successful") {
            this.componentList = this.users.users;
            document.getElementById("message").classList.remove('text-danger');
            document.getElementById("message").classList.add('text-success');
            this.message = "Save successful";
            setTimeout(() => {
                document.getElementById("message").classList.remove('text-success');
                document.getElementById("message").classList.add('text-danger');
                this.message = null
            }, 3000);
        } else {
            this.message = this.returnData.message;
        }
    }

    onUserResponce() {
        this.loadedUser = false;
        if (this.returnData.message == "retrieval successful") {
            this.users.users = this.returnData.data;
            this.focusFunction(this.users.users[0]);
            this.setComponentList(this.users.users);
            this._httpService.retrievePromise('group')
            .then(
                    (data) => {this.returnData = data ; this.onGroupResponce();}
                )
            .catch(
                    (error) => this.handleError(error)
                );
        } else {
        }
    }


    onCancel() {
        this.newEntry = false;
        this.resetForm();
        if (this.rowSelected) {
            for (var i=0; i < this.users.users.length; i++) {
                if (this.users.users[i]['id'].toString() == this.rowSelected) {
                    this.focusFunction(this.users.users[i]);
                }
            }
        } else {
            this.focusFunction(this.componentList[0]);
        }
    }

    newUser() {
        this.newEntry = true;
        this.rowSelected = null;
        this.usrName = null;
        this.usrUserName = null;
        this.usrEmail = null;
        this.usrGroup = null;
        this.eusrStatus= true;
        this.resetForm();
        setTimeout(() => document.getElementById('usrName').focus(), 0.1);
    }

    searchEntry(ev:Event):void {
        //console.log((<HTMLTextAreaElement>ev.target).value);
        this.searchValue = (<HTMLTextAreaElement>ev.target).value.toLowerCase();
        this.componentList = this.users.users.filter(function( obj ) {
            return (obj['username'].toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1);
        });
    }

}
import { Component, OnInit, ApplicationRef } from '@angular/core';

import { BaseEntryComponent } from '../base.entry.component';

import { HTTPService } from '../services/http.service';
import { RegisterInsuredService } from '../services/elements.service';
import { MessagingService } from '../services/messaging.service';

declare var jQuery:any;

@Component({
    selector: 'admin-insuredregistration',
    templateUrl: './admin-insuredregistration.component.html',
})

/*
 * Insured users of the system
 * can have the following statuses:
 * 
 * pq - "pending query" is set on creation of an insured user account and will require the user to submit answers to the dynamically generated questions
 * uv - "unverified" requires the user to verify their email address using the sent link
 * pe - "pre-processing" is the status of a user after successful email verification and now awaiting processing by administration
 * pr - "processing"
 * nc - "non-compliant"
 * qr - "querying"
 * co - "completed" is the status set by an administrator
 * fr - "fraudulent" activity suspected
 * 
 */

export class AdminInsuredRegistrationComponent extends BaseEntryComponent implements OnInit {
    public registeredInsured:any;

    private queryOne:any;
    private queryTwo:any;
    private queryThree:any;
    loadedInsured:boolean = false;

    constructor(private httpService: HTTPService, private applicationRef: ApplicationRef, private _RegisterInsuredService: RegisterInsuredService, private _messagingService: MessagingService) {
        super();
    }

    ngOnInit(): void {
        this.loadedInsured = false;
        var obja = {
                "search": [
                    {
                        "column":"status",
                        "operator":"!=",
                        "value":"dl"
                    }
                ]
            }
        this.httpService.retrievePromise('insured/registered', obja)
        .then(
                (data) => {this.returnData = data ; this.onRegisterInsuredResponce();}
            )
        .catch(
                (error) => this.handleError(error)
            );
        
    }

    onRegisterInsuredResponce() {
        this.loadedInsured = false;
        if (this.returnData.message == "retrieval successful") {
            this._RegisterInsuredService.insured = this.returnData.data;
            this.focusFunction(this._RegisterInsuredService.insured[0]);
            this.setComponentList(this._RegisterInsuredService.insured);
            this.loadedInsured = true;
        } else {
        }
    }

    focusFunction(row:any):void {
        if(row != undefined) {
            this.rowSelected = row['id'];
            this.registeredInsured = row;

            this.queryOne = row['insuredRegisteredQueries'][0];
            this.queryTwo = row['insuredRegisteredQueries'][1];
            this.queryThree = row['insuredRegisteredQueries'][2];
        }
    }

    allowDrop(ev:DragEvent) {
        ev.preventDefault();
    }

    drop(ev:DragEvent, action:string) {
        ev.preventDefault();
        var result = this.componentList.filter(function( obj ) {
          return obj.id == Number(ev.dataTransfer.getData("text"));
        });
        switch (action) {
            case "pe":
                //result[0]['status'] = 'pe';
                break;
            case "pr":
                if (result[0]['status'] == 'pe' || result[0]['status'] == 'qr'){
                    result[0]['status'] = 'pr';
                    this.updateInsuredProcessing(result[0]);
                }
                break;
            case "qr":
                if (result[0]['status'] == 'pr'){
                    result[0]['status'] = 'qr';
                    this.updateInsuredQuerying(result[0]);
                }
                break;
            case "co":
                if (result[0]['status'] == 'pr'){
                    result[0]['status'] = 'co';
                    this.updateInsuredCompleted(result[0]);
                }
                break;
        }
    }

    changStatus(insured:any, status:string) {
        insured['status'] = status;
        this.updateInsured(insured);
    }

    completeRegistration(insured:any) {
        this._messagingService.createMessageAlert("Please wait while we complete Insured Registration.", false);
        this.httpService.genericPromise('insured/registered/reforge/completed', insured)
            .then(
                    (data) => {this.returnData = data ; this.onResponceComplete(insured);}
                );
    }

    onResponceComplete(insured:any) {
        if (this.returnData.message == "reforge successful") {
            insured['status'] = 'co';
            this._messagingService.createMessageAlert("Registration Completed.", true);
        } else {
            this._messagingService.createMessageAlert("Error changing insured status. Please contact Administrator :", true);
        }
    }

    onResponceSave() {
        if (this.returnData.message == "reforge successful") {

        } else {
            //alert("Error changing employer stataus. Please contact Administrator :" +this.returnData.message);
        }
    }

    updateInsured(insured:any) {
        this.httpService.genericPromise('insured/registered/reforge/any', insured)
            .then(
                    (data) => {this.returnData = data ; this.onResponceSave();}
                );
    }

    updateInsuredQuerying(insured:any) {
        this.httpService.genericPromise('insured/registered/reforge/querying', insured)
            .then(
                    (data) => {this.returnData = data ; this.onResponceSave();}
                );
    }

    updateInsuredProcessing(insured:any) {
        this.httpService.genericPromise('insured/registered/reforge/processing', insured)
            .then(
                    (data) => {this.returnData = data ; this.onResponceSave();}
                );
    }

    updateInsuredFraudulent(insured:any) {
        this.httpService.genericPromise('insured/registered/reforge/fraudulent', insured)
            .then(
                    (data) => {this.returnData = data ; this.onResponceSave();}
                );
    }

    updateInsuredCompleted(insured:any) {
        this.httpService.genericPromise('insured/registered/reforge/completed', insured)
            .then(
                    (data) => {this.returnData = data ; this.onResponceSave();}
                );
    }

    startDrag(ev:DragEvent) {
        //ev.preventDefault();
        ev.dataTransfer.setData("text", (<HTMLTextAreaElement>ev.target).id)
    }

    searchEntry(ev:Event):void {
        this.componentList = this._RegisterInsuredService.insured.filter(function( obj ) {
            return (obj['ssn'].toString().toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1 || obj['name'].toString().toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1 || obj['status'] != 'co');
        });
    }

    changeStatus(event:Event, id: number) {
        var obj = {
            id: id,
            status : ((<HTMLInputElement>event.target).checked?'A':'D')
        }
        this.httpService.reforgePromise('user', obj)
        .then(
                (data) => {this.returnData = data ; this.onResponceStatusSave(event, id);}
            );
    }

    onResponceStatusSave(event:Event, id: number) {
        if (this.returnData.message == "reforge successful") {
            for (var i=0; i < this._RegisterInsuredService.insured.length; i++) {
                if (this._RegisterInsuredService.insured[i]['userId'] == id) {
                    this._RegisterInsuredService.insured[i]['active'] = (<HTMLInputElement>event.target).checked
                }
            }
        } else {
            //alert("Error changing employer stataus. Please contact Administrator :" +this.returnData.message);
        }
    }

}
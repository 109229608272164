import { Component, OnInit, ApplicationRef } from '@angular/core';

import { BaseEntryComponent } from '../base.entry.component';

import { HTTPService } from '../services/http.service';
import { Schedule } from '../services/elements';
import { ScheduleNotDownloadedService } from '../services/elements.service';
import { saveAs as importedSaveAs } from "file-saver";

@Component({
    selector: 'admin-download',
    templateUrl: './admin-download.component.html',
})
export class AdminDownloadComponent extends BaseEntryComponent implements OnInit {
    order:string = 'ascending';
    selected:string = null;
    returnFile: any;
    schedules:any;

    //private employees:Employee[];

    //values in form
    usrName: string;
    usrUserName: string;
    usrEmail: string;
    usrGroup: string;


    constructor(private _httpService: HTTPService, private applicationRef: ApplicationRef, private _ScheduleNotDownloadedService:ScheduleNotDownloadedService ) {
        super();
    }

    ngOnInit(): void {
        this.setComponentList(this._ScheduleNotDownloadedService.schedule);
    }

    nimsFIle() {
        var obj = {
            "id": this.rowSelected
        }
        this._httpService.genericDownloadPromise('schedule/download/scheduletext', obj)
        .then(
                (data) => {this.returnFile = data ; this.onNimsResponceSave();}
            );
    }

    onNimsResponceSave() {
        var blob = new Blob([this.returnFile], {type: 'text/plain'});
        importedSaveAs(blob, 'Nims Upload '+this.getMonth(this.schedules.month)+" "+this.schedules.year+" #"+this.schedules.scheduleNumber+'.txt');
        for (var i=0; i < this._ScheduleNotDownloadedService.schedule.length; i++) {
            if (this._ScheduleNotDownloadedService.schedule[i]['id'].toString() == this.rowSelected) {
                this._ScheduleNotDownloadedService.schedule[i]['downloaded'] = 1;
                this.rowSelected = null;
            }
        }
    }

    removeSchedule(id) {
        for (var i=0; i < this._ScheduleNotDownloadedService.schedule.length; i++) {
            if (this._ScheduleNotDownloadedService.schedule[i]['id'].toString() == this.rowSelected) {
                this._ScheduleNotDownloadedService.schedule.splice(i,1);
            }
        }
        this.componentList = this._ScheduleNotDownloadedService.schedule;
        this.rowSelected = null;
    }

    focusFunction(row:any):void {
        if(row != undefined) {
            this.rowSelected = row['id'];
            this.schedules = row;
        }
    }

    searchEntry(ev:Event):void {
        //console.log((<HTMLTextAreaElement>ev.target).value);
        this.searchValue = (<HTMLTextAreaElement>ev.target).value.toLowerCase();
        this.componentList = this._ScheduleNotDownloadedService.schedule.filter(function( obj ) {
            return (obj['registrationNumber'].toString().toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1);
        });
    }

}
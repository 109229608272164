import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HTTPService } from './services/http.service';
import { MessagingService } from './services/messaging.service';

import { JwtHelperService } from '@auth0/angular-jwt';
import { NgxCaptchaModule } from 'ngx-captcha';

import { BaseComponent } from './base.component';

/**
 * This is the registration component that controls the registration interface
 */
@Component({
  selector: 'registration',
  templateUrl: './registration.component.html',
})
export class RegistrationComponent extends BaseComponent implements OnInit {
	jwtHelper: JwtHelperService = new JwtHelperService();

	employerNumber: string;
	employerName: string;
	contactPerson: string;
	contactNumber: string;
	emailAddress: string;

	captchaInput:any;

    completeMessage:string;

	@ViewChild('captcha') captcha: any;

	constructor (
		private router: Router,
		private _httpService: HTTPService,
		private _messagingService: MessagingService
		) {
		super();
		this.employerNumber = null;
		this.employerName = '';
		this.contactPerson = '';
		this.contactNumber = '';
		this.emailAddress = '';
	}

	ngOnInit() {
 	}

	onSubmit() { 
		this.disabled = true;
		this.message = "submitting.........";
		var obj = { 
			"username":this.employerNumber, 
			"name":this.employerName,
			"emailAddress":this.emailAddress,
			"contactPerson":this.contactPerson,
			"contactNumber":this.contactNumber
		};
		this._messagingService.createMessageAlert("Please wait while we process your Registration.", false);
		this._httpService.authPromise('user/register', obj)
		.then(
				(data) => {this.returnData = data ; this.onResponce();}
			);
	}

	onResponce() {
		this.captcha.reCaptchaApi.reset();
		this._messagingService.hideMessageAlert();
		if (this.returnData.message == "reforge successful") {
			this.completeMessage = "Please check your email to verify your registration. Email may appear in your spam folder.";
			this.active = false;
		} else if (this.returnData.message == "Account already registered. Check email for verification link. Email may appear in your spam folder.") {
			this.completeMessage = "This employer has already registered. Please check email for verification link.";
			this.active = false;
		} else if (this.returnData.message == "Access denied.") {
			this.message = "This Employer is already Registered.";
			this.disabled = false;
			this.resetForm();
		} else {
			this.message = "Information provided is incorrect.";
			this.disabled = false;
			this.resetForm();
		}
	}

	handleSuccess($event) {
		this.captchaInput = true;
	}
}

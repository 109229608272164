import { Component } from '@angular/core';


/**
 * This is the page not found component displayed when a page that does not exists is visited
 */
@Component({
	selector: 'page-not-found',
	template: `
	<div class="row">
		<div class="col-md-4"></div>
		<div class="col-md-4">
    		<h1>PAGE NOT FOUND</h1>
    	</div>
		<div class="col-md-4"></div>
	</div>
  `,
})
export class PageNotFoundComponent {}
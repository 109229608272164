import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HTTPService } from './services/http.service';
import { UserService } from './services/user.service';
import { MenuService } from './services/menu.service';
import { BaseComponent } from './base.component';

import { JwtHelperService } from '@auth0/angular-jwt';

/**
 * This is the login component that controls the login interface
 */
@Component({
  selector: 'login',
  templateUrl: './login.component.html',
})
export class LoginComponent extends BaseComponent implements OnInit {
	jwtHelper: JwtHelperService = new JwtHelperService();
	userName: string;
	userPassword: string;
	rememberMe:boolean;

	constructor (
		private router: Router,
		private _httpService: HTTPService,
		private user:UserService,
		private menu:MenuService
		) {
		super();
		this.userName = '';
		this.userPassword = '';
	}

	ngOnInit() {
    	if (localStorage.getItem("id_token") !== null) {
			this.router.navigate(['/dashboard']);
        }
 	}

	onSubmit() { 
		this.disabled = true;
		this.message = "Logging in.........";
		var obj = { "username":this.userName, "password":this.userPassword};
		this._httpService.authPromise('authentication/login', obj)
		.then(
				(data) => {this.returnData = data ; this.onResponce();}
			).catch(this.handleError);;
	}

	onResponce() {
		if (this.returnData.message == "authorisation successful" && ((this.jwtHelper.decodeToken(this.returnData.token)).typ == 'E' || (this.jwtHelper.decodeToken(this.returnData.token)).typ == 'A') ) {
			sessionStorage.setItem("id_token", this.returnData.token);
			this.user.user = this.jwtHelper.decodeToken(this.returnData.token);
			if (this.rememberMe){
				localStorage.setItem('id_token', this.returnData.token);
			}
			this.menu.setMenu(this.user.user.typ);
			this.user.loadData();
			this.router.navigate(['/dashboard']);
		} else {
			this.message = "Login information is incorrect.";
			this.disabled = false;
			this.resetForm()
		}
	}
}

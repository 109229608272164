import { Component, OnInit, ApplicationRef } from '@angular/core';

import { BaseEntryComponent } from '../base.entry.component';

import { HTTPService } from '../services/http.service';
import { RegisterEmployerService } from '../services/elements.service';

@Component({
    selector: 'admin-employer',
    templateUrl: './admin-employer.component.html',
})
export class AdminEmployerComponent extends BaseEntryComponent implements OnInit {
    //values in form
    emplyerRegNumber: string;
    emplyerName: string;
    emplyerEmail: string;
    emplyerContact: string;
    emplyerNumber: string;
    emplyerStatus: string;
    emplyerUserId: string;

    editObj:any;
    loadedEmployers:boolean = false;

    constructor(private _httpService: HTTPService, private applicationRef: ApplicationRef, private employers: RegisterEmployerService ) {
        super();
    }

    ngOnInit(): void {
        this.loadedEmployers = false;
        var obja = {
                "search": [
                    {
                        "column":"status",
                        "operator":"!=",
                        "value":"dl"
                    }
                ]
            }
        this._httpService.retrievePromise('employer', obja)
        .then(
                (data) => {this.returnData = data ; this.onRegisterEmployerResponce();}
            )
        .catch(
                (error) => this.handleError(error)
            );
    }

    onRegisterEmployerResponce() {
        this.loadedEmployers = false;
        if (this.returnData.message == "retrieval successful") {
            this.employers.employer = this.returnData.data;
            this.focusFunction(this.employers.employer[0]);
            this.setComponentList(this.employers.employer);
            this.loadedEmployers = true;
        } else {
        }
    }

    focusFunction(row:any):void {
        if(row != undefined) {
            this.rowSelected = row['id'];
            this.emplyerRegNumber = row['registrationNumber'];
            this.emplyerName = row['name'];
            this.emplyerEmail = row['emailAddress'];
            this.emplyerContact = row['contactPerson'];
            this.emplyerNumber = row['contactNumber'];
            this.emplyerStatus = row['status'];
            this.emplyerUserId = row['userId'];
            this.resetForm();
        }
    }

    onSubmit() {
        this.editObj =
        {
            'registrationNumber': this.emplyerRegNumber,
            'name':this.emplyerName,
            'emailAddress': this.emplyerEmail,
            'contactPerson': this.emplyerContact,
            'contactNumber': this.emplyerNumber,
            'status': this.emplyerStatus,
            'userId': this.emplyerUserId
        };
        if (this.rowSelected) {
            this.editObj.id = this.rowSelected;
        }
        this.disabled = true;
        this.message = "Saving.........";
        this._httpService.reforgePromise('employer', this.editObj)
        .then(
                (data) => {this.returnData = data ; this.onResponceSave();}
            );
    }

    onResponceSave() {
        this.disabled = false;
        if (this.returnData.message == "reforge successful") {
            //this.getEmployees();
            if (this.editObj.id) {
                for (var i=0; i < this.employers.employer.length; i++) {
                    if (this.employers.employer[i]['id'] == this.editObj.id) {
                        this.employers.employer[i].registrationNumber = this.editObj.registrationNumber;
                        this.employers.employer[i].name = this.editObj.name;
                        this.employers.employer[i].emailAddress = this.editObj.emailAddress;
                        this.employers.employer[i].contactPerson = this.editObj.contactPerson;
                        this.employers.employer[i].contactNumber = this.editObj.contactNumber;
                        this.employers.employer[i].status = this.editObj.status;
                    }
                }
            } else {
                this.editObj.id = this.returnData.id;
                this.employers.employer.push(this.editObj);
            }
            
            //save user
            var userObjNew =
            {
                "id": this.editObj.userId,
                "emailAddress": this.editObj.emailAddress,
                "name":this.editObj.name
            };
            this._httpService.reforgePromise('user', userObjNew)
            .then(
                    (data) => {this.returnData = data ; this.onResponceUserTableSave();}
                );

        } else {
            this.message = this.returnData.message;
        }
    }

    onResponceUserTableSave() {
        if (this.returnData.message && this.returnData.message == "reforge successful") {
            this.componentList = this.employers.employer;
            document.getElementById("message").classList.remove('text-danger');
            document.getElementById("message").classList.add('text-success');
            this.message = "Save successful";
            setTimeout(() => {
                document.getElementById("message").classList.remove('text-success');
                document.getElementById("message").classList.add('text-danger');
                this.message = null
            }, 3000);
        } else {
            this.message = "An error occurred.";
        }
    }

    onCancel() {
        this.resetForm();
        if (this.rowSelected) {
            for (var i=0; i < this.employers.employer.length; i++) {
                if (this.employers.employer[i]['id'].toString() == this.rowSelected) {
                    this.focusFunction(this.employers.employer[i]);
                }
            }
        } else {
            this.focusFunction(this.componentList[0]);
        }
    }

    searchEntry(ev:Event):void {
        //console.log((<HTMLTextAreaElement>ev.target).value);
        this.searchValue = (<HTMLTextAreaElement>ev.target).value.toLowerCase();
        this.componentList = this.employers.employer.filter(function( obj ) {
            return (obj['name'].toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1 || obj['registrationNumber'].toString().toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1);
        });
    }

}
import { Component, OnInit, ApplicationRef, ViewChild } from '@angular/core';

import { BaseEntryComponent } from '../base.entry.component';
import { ScheduleViewComponent } from '../components/schedule-view.component';

import { HTTPService } from '../services/http.service';
import { RegisterEmployerService } from '../services/elements.service';

import { Schedule, NonSubmission } from '../services/elements';

@Component({
    selector: 'admin-schedule',
    templateUrl: './admin-schedule.component.html',
})
export class AdminScheduleComponent extends BaseEntryComponent implements OnInit {
    @ViewChild('scheduleView') scheduleView: ScheduleViewComponent;
    private employerSchedules: Schedule[];
    private employerNonSchedules: NonSubmission[];
    private scheduleIndex:any;
    selectSchedule:any;
    selectNonSubmission:any;
    order:string = 'ascending';
    selected:string = null;
    showList:boolean = false;
    loadedEmployers:boolean = false;

    constructor(private _httpService: HTTPService, private applicationRef: ApplicationRef, private employers: RegisterEmployerService  ) {
        super();
    }

    ngOnInit(): void {
        this.loadedEmployers = false;
        var obja = {
                "search": [
                    {
                        "column":"status",
                        "operator":"!=",
                        "value":"dl"
                    }
                ]
            }
        this._httpService.retrievePromise('employer', obja)
        .then(
                (data) => {this.returnData = data ; this.onRegisterEmployerResponce();}
            )
        .catch(
                (error) => this.handleError(error)
            );
    }

    onRegisterEmployerResponce() {
        this.loadedEmployers = false;
        if (this.returnData.message == "retrieval successful") {
            this.employers.employer = this.returnData.data;
            this.setComponentList(this.employers.employer);
            this.loadedEmployers = true;
        } else {
        }
    }

    focusFunction(row:any):void {
        this.scheduleIndex = {
            years : {}
        };
        if(row != undefined) {
            this.rowSelected = row['id'];
            this.showList = false;
            this.selectSchedule = null;
            this.selectNonSubmission = null;
            var obj2 = {
                "search": [
                    {
                        "column":"registrationNumber",
                        "operator":"=",
                        "value":row['registrationNumber']
                    },
                    {
                        "append":"and",
                        "column":"void",
                        "operator":"!=",
                        "value":"1"
                    },
                    {
                        "append":"and",
                        "column":"submissionDate",
                        "operator":"!=",
                        "value":"NULL"
                    }
                ]
            }
            this._httpService.genericPromise('schedule/basic/retrieve/any', obj2)
            .then(
                    (data) => {this.returnData = data ; this.onScheduleResponce();}
                );
            var obj3 = {
                "search": [
                    {
                        "column":"registrationNumber",
                        "operator":"=",
                        "value":row['registrationNumber']
                    }
                ]
            }
            this._httpService.retrievePromise('nonsubmission', obj3)
            .then(
                    (data) => {this.returnData = data ; this.onNonSubmissionResponce();}
            )
        }
    }

    onScheduleResponce() {
        if (this.returnData.message == "retrieval successful") {
            this.employerSchedules = this.returnData.data;
            this.getScheduleIndex();
        } else {
        }
    }

    onNonSubmissionResponce() {
        if (this.returnData.message == "retrieval successful") {
            this.employerNonSchedules = this.returnData.data;
            this.getNonSubmissionIndex();
        } else {

        }
    }

    getScheduleIndex() {
        for(var i = 0;i<this.employerSchedules.length;i++) {
            var idLoop = this.employerSchedules[i]['id'];
            var yearOfLoop = this.employerSchedules[i]['year'];
            var monthOfLoop = this.employerSchedules[i]['month'];
            var scheduleNumberOfLoop = this.employerSchedules[i]['scheduleNumber'];

            var scheduleMethod = (this.employerSchedules[i]['uploadMethod'] == 'EW')? "upload": "schedule";

            if (typeof this.scheduleIndex.years[yearOfLoop] == 'undefined') {
                this.scheduleIndex.years[yearOfLoop] = {
                    year : yearOfLoop,
                    periods : {}
                };
            }
            if (typeof this.scheduleIndex.years[yearOfLoop].periods[monthOfLoop] == 'undefined') {
                this.scheduleIndex.years[yearOfLoop].periods[monthOfLoop]={
                    month : monthOfLoop,
                    schedules : {}
                };
            }
            if (typeof this.scheduleIndex.years[yearOfLoop].periods[monthOfLoop].schedules[scheduleNumberOfLoop] == 'undefined') {
                this.scheduleIndex.years[yearOfLoop].periods[monthOfLoop].schedules[scheduleNumberOfLoop]={
                    id : idLoop,
                    number : scheduleNumberOfLoop,
                    type: scheduleMethod
                };
            }
        }
        this.showList = true;
    }

    getNonSubmissionIndex() {
        for(var i = 0;i<this.employerNonSchedules.length;i++) {
            var idLoop = this.employerNonSchedules[i]['id'];
            var yearOfLoop = this.employerNonSchedules[i]['year'];
            var monthOfLoop = this.employerNonSchedules[i]['month'];
            var scheduleNumberOfLoop = 1;
            if (typeof this.scheduleIndex.years[yearOfLoop] == 'undefined') {
                this.scheduleIndex.years[yearOfLoop] = {
                    year : yearOfLoop,
                    periods : {}
                };
            }
            if (typeof this.scheduleIndex.years[yearOfLoop].periods[monthOfLoop] == 'undefined') {
                this.scheduleIndex.years[yearOfLoop].periods[monthOfLoop]={
                    month : monthOfLoop,
                    schedules : {}
                };
            }
            if (typeof this.scheduleIndex.years[yearOfLoop].periods[monthOfLoop].schedules[scheduleNumberOfLoop] == 'undefined') {
                this.scheduleIndex.years[yearOfLoop].periods[monthOfLoop].schedules[scheduleNumberOfLoop]={
                    id : idLoop,
                    number : scheduleNumberOfLoop,
                    type: 'nonSubmission'
                };
            }
        }
    }

    setSchedule(schedule:any = -1):any {
        this.selectSchedule = null;
        this.selectNonSubmission = null;
        if (schedule.id === -1) {
            return new Schedule();
        } else if (schedule.nonSubmission) {
            //seach for Nonsubmission by ID
            for(var i = 0;i<this.employerNonSchedules.length;i++) {
                if (this.employerNonSchedules[i]['id'] == schedule.id) {
                    this.selectNonSubmission = this.employerNonSchedules[i];
                }
            }
        } else {
            //load schedule
            var obj4 = {
                "search": [
                    {
                        "column":"id",
                        "operator":"=",
                        "value":schedule.id
                    }
                ]
            }
            this._httpService.retrievePromise('schedule', obj4)
            .then(
                    (data) => {this.returnData = data ; this.onScheduledetailLoad();}
                );
        }
    }

    onScheduledetailLoad() {
        if (this.returnData.message == "retrieval successful") {
            this.selectSchedule = this.returnData.data[0];
            this.scheduleView.setSummary(this.selectSchedule);
        } else {
        }
    }

    searchEntry(ev:Event):void {
        //console.log((<HTMLTextAreaElement>ev.target).value);
        this.searchValue = (<HTMLTextAreaElement>ev.target).value.toLowerCase();
        this.componentList = this.employers.employer.filter(function( obj ) {
            return (obj['name'].toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1 || obj['registrationNumber'].toString().toLowerCase().indexOf((<HTMLTextAreaElement>ev.target).value.toLowerCase()) !== -1);
        });
    }
}
import { Component, OnInit, ApplicationRef } from '@angular/core';

import { ScheduleEntryComponent } from '../components/schedule-entry.component';

import { Schedule } from '../services/elements';
import { HTTPService } from '../services/http.service';

@Component({
    selector: 'user-schedule',
    templateUrl: './user-schedule-multi.component.html',
})
export class UserScheduleMultiComponent implements OnInit {
    public scheduleIndex:any;
    public schedules:Schedule;
    public employeeList:boolean = false;


    constructor(private httpService: HTTPService, private applicationRef: ApplicationRef ) {}

    ngOnInit(): void {
        //this.getSchedules();
        this.schedules = new Schedule;
        /*this.schedules.schedulesMonthly = [
        {
            id:1,
            ssn: 253951,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'Actual Earnings': 3000,
            'Weeks Worked': 5,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        {
            id:2,
            ssn: 253910,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'Actual Earnings': 3000,
            'Weeks Worked': 5,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        {
            id:3,
            ssn: 253952,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'Actual Earnings': 3000,
            'Weeks Worked': 5,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        {
            id:4,
            ssn: 253920,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'Actual Earnings': 3000,
            'Weeks Worked': 5,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        {
            id:5,
            ssn: 253850,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'Actual Earnings': 3000,
            'Weeks Worked': 5,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        {
            id:6,
            ssn: 253959,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'Actual Earnings': 3000,
            'Weeks Worked': 5,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        {
            id:7,
            ssn: 251950,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'Actual Earnings': 3000,
            'Weeks Worked': 5,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        {
            id:8,
            ssn: 255950,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'Actual Earnings': 3000,
            'Weeks Worked': 5,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        {
            id:9,
            ssn: 253953,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'Actual Earnings': 3000,
            'Weeks Worked': 5,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        {
            id:10,
            ssn: 253952,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'Actual Earnings': 3000,
            'Weeks Worked': 5,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        {
            id:11,
            ssn: 253951,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'Actual Earnings': 3000,
            'Weeks Worked': 5,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        ];
        this.schedules.schedulesWeekly = [
        {
            id:1,
            ssn: 253951,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'week_1': 3000,
            'week_2': 3000,
            'week_3': 3000,
            'week_4': 3000,
            'week_5': 3000,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        {
            id:2,
            ssn: 253910,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'week_1': 3000,
            'week_2': 3000,
            'week_3': 3000,
            'week_4': 3000,
            'week_5': 3000,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        {
            id:3,
            ssn: 253952,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'week_1': 3000,
            'week_2': 3000,
            'week_3': 3000,
            'week_4': 3000,
            'week_5': 3000,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        {
            id:4,
            ssn: 253920,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'week_1': 3000,
            'week_2': 3000,
            'week_3': 3000,
            'week_4': 3000,
            'week_5': 3000,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        {
            id:5,
            ssn: 253850,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'week_1': 3000,
            'week_2': 3000,
            'week_3': 3000,
            'week_4': 3000,
            'week_5': 3000,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        {
            id:6,
            ssn: 253959,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'week_1': 3000,
            'week_2': 3000,
            'week_3': 3000,
            'week_4': 3000,
            'week_5': 3000,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        {
            id:7,
            ssn: 251950,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'week_1': 3000,
            'week_2': 3000,
            'week_3': 3000,
            'week_4': 3000,
            'week_5': 3000,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        {
            id:8,
            ssn: 255950,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'week_1': 3000,
            'week_2': 3000,
            'week_3': 3000,
            'week_4': 3000,
            'week_5': 3000,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        {
            id:9,
            ssn: 253953,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'week_1': 3000,
            'week_2': 3000,
            'week_3': 3000,
            'week_4': 3000,
            'week_5': 3000,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        {
            id:10,
            ssn: 253952,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'week_1': 3000,
            'week_2': 3000,
            'week_3': 3000,
            'week_4': 3000,
            'week_5': 3000,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        {
            id:11,
            ssn: 253951,
            'First Name': 'Adrian',
            'Last Name': 'Da Silva',
            'week_1': 3000,
            'week_2': 3000,
            'week_3': 3000,
            'week_4': 3000,
            'week_5': 3000,
            'Insurable Earnings': 3000,
            'Employer Contribution': 2,
            'Employee Contribution': 3,
        },
        ];*/
    }

    getSchedules(): void {
        //this.httpService.getSchedules().then (
            //(schedules) => {this.schedules. = schedules;}
        //);
    }
}